


    import {
    IonDatetime,
    IonRow,
    IonCol

    } from '@ionic/vue';

  import { defineComponent, PropType } from 'vue'
  import { VueScrollPicker, VueScrollPickerOption } from 'vue-scroll-picker'
  import 'vue-scroll-picker/lib/style.css'
  import moment from 'moment'

  export default defineComponent({
    components: {
    IonDatetime,
    IonRow,
    IonCol,
    VueScrollPicker, // export VueScrollPicker is component
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  emits: ["update:modelValue", "onDismiss"],
  methods: {

    strPad(num:any, size:number) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    },

    changeDt() {
        if(this.data.dia_nascimentos){

          const dd = this.strPad(this.data.dia_nascimentos, 2)
          const mmyyy = moment(this.data.mesano_nascimento).format("yyyy-MM");
          const dataDeNascimento = moment(mmyyy+"-"+dd.padStart(2, '0'), 'YYYY-MM-DD', true);

          if (dataDeNascimento.isValid()) {

            this.data.nascimento = mmyyy+"-"+dd.padStart(2, '0');

            const data = moment(this.data.nascimento); // substitua esta data pela que você deseja obter o total de dias
            let totalDias = data.daysInMonth();

            if( !totalDias || totalDias < 26)totalDias=31

            this.options = [];

            for (let i = 1; i <= totalDias; i++) {
              this.options.push(i);
            }

            this.$emit("update:modelValue", this.data);

          }else{

            const data = moment( mmyyy+"-01"); // substitua esta data pela que você deseja obter o total de dias
            let totalDias = data.daysInMonth();

            this.options = [];

            if( !totalDias || totalDias < 26)totalDias=31

            for (let i = 1; i <= totalDias; i++) {
              this.options.push(i);
            }

          }

        }else{

          this.options = [];
          let totalDias = 31
         
          for (let i = 1; i <= totalDias; i++) {
            this.options.push(i);
          }

        }
      },
    },
    data() {
        return {
          currentValue: this.data.dia_nascimentos,
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31] as number[],
        }
    },
    setup() {
      return {
        texto_pesquisa: ""
      };
    },
    mounted() {

          setTimeout(()=>{
              /*this.options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,31]
              this.currentValue = this.data.dia_nascimentos*/

              this.changeDt()
              
          }, 300);

    }
  })
