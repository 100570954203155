

import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  IonApp,
  IonIcon,
  IonLabel,
  IonMenuToggle,
  IonFooter,
  IonButton,
  IonButtons,
  IonModal,
  IonGrid,
  IonAvatar,
  IonListHeader,
  IonItem,
  IonFab,
  IonFabButton,
  IonCol, IonBadge, IonRow,IonInput } from "@ionic/vue";

import { bag,homeOutline,gridOutline,cart, locationOutline, chevronDown, chevronBack,helpCircle, checkmark, close,search, ellipsisVerticalCircleOutline, chevronForward, bagHandleOutline, storefrontOutline } from 'ionicons/icons';


import { useStore } from "vuex";

import { defineComponent, ref } from 'vue';

import { Drivers, Storage } from '@ionic/storage'




export default defineComponent({
  name: "footermenu",
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    IonApp,
    IonIcon,
    IonLabel,
    IonMenuToggle,
    IonFooter,
    IonGrid,
    IonAvatar,
    IonListHeader,
    IonButton,
    IonButtons,
    IonModal,
    IonItem,
    IonCol,
    IonBadge,
    IonRow,
    IonInput,
    IonFab,
    IonFabButton
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => []
    }
  },
  watch: {
    cartItemNumber: function(newVal, oldVal) {
      this.animated=this.animated==1?0:1
    }
  },
  computed: {
    carts():Number {
      return this.store.getters.carts
    },
    cartItemNumber():Number {
      return this.store.getters.cartItemNumber
    },
    cartTotal():Number  {
      return this.store.getters.cartTotal
    },
    isAnimation():Number  {
      return this.animated
    }
},
  emits: ["onDismiss", "onBack" ],
  methods: {
    voltar(){

      this.$emit("onBack", "{}")

    },
    gotoCart(){

      this.$router.push("/carrinho")
      //this.$emit("onBack", "{}")

    },
    navigate(url:any){

      this.$router.push(url)
      //this.$emit("onBack", "{}")

    }
  },
  data() {
    return {
      safeTop: 0,
      animated: 1,
      chevronBack,bag, cart, ellipsisVerticalCircleOutline, chevronForward, homeOutline,gridOutline,storefrontOutline,
      descontoIcon : "/assets/icon/assets0005.svg"
    }
  },
  setup() {

    const store = useStore()
    return { store }

  },
  mounted() {

    this.safeTop = this.store.state.safeTop

    //this.safeTop=47

  }
});
