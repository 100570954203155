


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonLoading,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonNote,
  IonTitle,
  IonButtons,
  IonBackButton,
  alertController,
  IonInput,
  IonCol,
  IonRow,
  IonRadio,
  IonModal,
  IonRadioGroup,
  IonSpinner
} from '@ionic/vue';

import { cart, chevronForward, chevronBack,helpCircle, checkmark, close, eye, eyeOff, qrCode, card } from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";

import VOtpInput from 'vue3-otp-input';

import {useStore} from "vuex";
import { defineComponent } from 'vue';

import { ref } from 'vue'

import moment from 'moment';

import  SafeArea  from '../components/SafeArea.vue'

export default defineComponent({
  components: {
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonNote,
    IonListHeader,
    IonList,
    IonCol,
    IonRow,
    IonRadio,
    IonModal,
    IonRadioGroup,
    alertController,
    IonLoading,
    IonSpinner,
    VOtpInput,
    ref,
    SafeArea
  },
  data() {
    return {
      chevronForward, chevronBack, helpCircle, checkmark, close, eye, eyeOff, qrCode, card,
      isOpenNumCartao : false,
      isOpenVirtual: false,
      isOpenListaEspera : false,
      isLoading : true,
      isOpenLoading:true,
      numCard:"",
      senha:"",
      tipo_menu : 0,
      cartoes : [],
      conta_digital : { cartao: "", saldo : 0, limite: 0, extrato: [] },
      cartao_virtual : { cartao: "0", cvv : "0", senha: "0", data_expiracao: "01/1999" },
      erro_pin:"",
      pin: { enviado: false, data: 0 },
      user_info : useStore().state.user.info
    }
  },
   methods: {
    formatCardNumber(value:any) {
      if (!value) return "";
      const formattedValue = value
        .replace(/\s+/g, "")
        .replace(/(\d{4})/g, "$1-")
        .slice(0, -1);
      return formattedValue;
    },
    getSenha(){
        alertController.create({
          header: 'Digite a senha do seu cartão',
          buttons: [
            {

              text: 'Voltar',
              role: 'cancel',
              handler: () => {

              },
            },
            {
              text: 'OK',
              role: 'confirm',
              handler: (imSenha) => {
                this.gerarVirtual(imSenha.senha)
              },
            },
          ],
          inputs: [
            {
              name: 'senha',
              placeholder: 'Senha',
              type: 'password',
              attributes: {
                maxlength: 8,
              },
            }
          ],
        }).then((response:any) => {

         response.present();

        });
    },
    voltar() {
      this.$router.back()
    },

    getVoucher (){
      this.isOpenNumCartao = true;
    },

    getConta (cartao: string){

      this.isOpenLoading = true;
      this.numCard = cartao;

      fintailapi
      .post('/conta/saldo', {'cashback': true })
      .then((response:any) => {
        this.isOpenLoading = false;
          const data = response.data
          if(data.success){

            this.isOpenNumCartao = false;
            this.isLoading = false;

            this.conta_digital = data.cartao;

            this.getExtrato({'cashback': true });

          }else{

            this.naoEncontrado();

            (this.$refs.otpInput as InstanceType<typeof VOtpInput>).clearInput()

          };

      }).catch((err)=>  this.isOpenLoading = false )

    },

    getContaVoucher (cartao: string){

      this.isOpenLoading = true;
      this.numCard = cartao;
      this.tipo_menu = 1
      fintailapi
      .post('/voucher/saldo', {'cartao': cartao, 'cpf': this.user_info.cpf })
      .then((response:any) => {
        this.isOpenLoading = false;
          const data = response.data
          if(data.success){

            this.isOpenNumCartao = false;
            this.isLoading = false;

            this.conta_digital = data.cartao;

            this.getExtratoVoucher("s");

          }else{

            this.naoEncontrado();

            (this.$refs.otpInput as InstanceType<typeof VOtpInput>).clearInput()

          };

      }).catch((err)=>  this.isOpenLoading = false )

    },


    getExtrato(cashback:any){

      moment.locale('pt-BR');

      fintailapi
      .post('/conta/extrato', {'cashback': cashback })
      .then((response:any) => {

          const data = response.data
          if(data.success){

            this.conta_digital.extrato = data.extrato;

          };

      })

    },

    getExtratoVoucher(cashback:any){

      moment.locale('pt-BR');

      fintailapi
      .post('/voucher/extrato', {'cartao': this.numCard , 'cpf': this.user_info.cpf })
      .then((response:any) => {

          const data = response.data
          if(data.success){

            this.conta_digital.extrato = data.extrato;

          };

      })

    },
    gerarVirtual(cashback:any){


      if( this.conta_digital.saldo==0 ){

        alertController.create({
                  header: 'Saldo',
                  message: 'Você não possui saldo para esta operação',
                  buttons: ['OK'],
              }).then((response:any) => {

                response.present();

              });
        return;
      }


      this.isOpenLoading = true;

        fintailapi
        .post('/conta/virtual', {'cashback': cashback})
        .then((response:any) => {
          this.isOpenLoading = false;
            const data = response.data
            if(data.success){

              this.isOpenVirtual=true;
              this.cartao_virtual = data.cartao;


            }else{

              alertController.create({
                  header: 'Dados inválidos',
                  message: 'Verifique os dados informados e tente novamente',
                  buttons: ['OK'],
              }).then((response:any) => {

                response.present();

              });

            };

        })

    },
    gerarVirtualVoucher(cashback:any){


      if(cashback != ""){
        this.numCard = cashback
      }

     this.isOpenLoading = true;

      fintailapi
      .post('/voucher/virtual',  {'cartao': this.numCard, 'cpf': this.user_info.cpf })
      .then((response:any) => {
        this.isOpenLoading = false;
          const data = response.data
          if(data.success){

            this.isOpenVirtual=true;
            this.cartao_virtual = data.cartao;


          }else{

            alertController.create({
                header: 'Dados inválidos',
                message: 'Verifique os dados informados e tente novamente',
                buttons: ['OK'],
            }).then((response:any) => {

              response.present();

            });

          };

      })

    },
    getCartoesColaboradoes(){

      moment.locale('pt-BR');

      fintailapi
      .get('/voucher/cartoes')
      .then((response:any) => {

          const data = response.data
          if(data.success){

            this.cartoes = data.cartoes;

          };

      })

    },
    getColaboradoesVirtual(cashback:any){


        if(cashback != ""){
          this.numCard = cashback
        }

        this.isOpenLoading = true;

        fintailapi
        .post('/voucher/cartoes/virtual',  {'cartao': this.numCard, 'cpf': this.user_info.cpf })
        .then((response:any) => {
          this.isOpenLoading = false;
            const data = response.data
            if(data.success){

              this.isOpenVirtual=true;
              this.cartao_virtual = data.cartao;


            }else{

              alertController.create({
                  header: 'Dados inválidos',
                  message: 'Verifique os dados informados e tente novamente',
                  buttons: ['OK'],
              }).then((response:any) => {

                response.present();

              });

            };

        })

    },

    async showOpcao(numCard:any){
      
          const alert = await alertController.create({
            header: 'XXXX-XXXX-XXXX-'+numCard,
            subHeader: "",
            buttons: [
              {
                text: 'Saldo',
                role: 'cancel',
                handler: () => {
                  this.getContaVoucher(numCard)
                },
              },
              {
                text: 'Cartão Virtual',
                role: 'confirm',
                handler: () => {

                  this.gerarVirtualVoucher(numCard)

                },
              },
            ],
          });

        await alert.present();
    },

    async gotoListaEspera(){

      this.isOpenNumCartao=false;
      this.voltar();
      const alert = await alertController.create({
          header: 'Lista de Espera',
          message: 'Você ja está em nossa lista de espera, assim que possível um de nossos agentes de crédito entrará em contato com uma proposta imperdível!',
          buttons: ['OK'],
      });

      await alert.present();

    },


    async naoEncontrado(){

    const alert = await alertController.create({
        header: 'Cartão não localizado',
        message: 'Verifique os 4 últimos dígitos e tente novamente',
        buttons: ['OK'],
    });

    await alert.present();

    },


    toHora(value:any) {
      if(!value)return "";
      value = value.replaceAll("/","-");
      return moment(value, "DD/MM/YYYY").format('HH')+"h";
    },

    toDia(value:any) {
      if(!value)return "";
      return moment(value, "DD/MM/YYYY").format('DD');
    },

    toMese(value:any) {
      if(!value)return "";
      return moment(value, "DD/MM/YYYY").format('MMMM');
    },

    toDiaMes(value:any) {
      if(!value)return "";
      return moment(value, "DD/MM/YYYY").format('DD/MM');
    }

  },
  created() {

    this.getConta("")
    this.getCartoesColaboradoes()

  },
  beforeRouteLeave(to:any, from:any, next:any){

    this.isOpenNumCartao = false;
     next();

  }

});


/*
@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        VOtpInput,
        alertController
}})

export default class Conta extends Vue {



  iconVoltar = allIcons.chevronBack
  icon_right = allIcons.chevronForward
  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox
  icon_qr = allIcons.qrCode



  user_info = useStore().state.user.info;


  voltar() {
    this.$router.back()
  }

  getConta (cartao: string){

    fintailapi
    .post('/conta/saldo', {'cartao': cartao, 'cpf': this.user_info.cpf })
    .then((response:any) => {


      const data = response.data
      if(data.success){
        this.isOpenNumCartao = false;
      }else{
        this.naoEncontrado();




      };




    })

  }

  async naoEncontrado(){

    const alert = await alertController.create({
        header: 'Cartão não localizado',
        message: 'Verifique os 4 últimos dígitos e tente novamente',
        buttons: ['OK'],
    });

    await alert.present();

  }

  created() {
    //this.getConta()
  }

  setup() {
    const otpInput = ref(null)

    const handleOnComplete = (value: string) => {
      console.log('OTP completed: ', value);
    };

    const handleOnChange = (value: string) => {
      console.log('OTP changed: ', value);
    };

     const clearInput = () => {
      otpInput.value.clearInput()
    }

    return { handleOnComplete, handleOnChange, clearInput, otpInput };
  }

}
*/
