


import {
  IonPage,
  IonContent ,
    menuController,
  IonItem,
  IonMenu,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
   IonFooter,
  IonIcon,
  IonCol,
  IonRow,
  IonButtons,
  IonBackButton
} from '@ionic/vue';

import { defineComponent, ref } from 'vue';

import { cart,person, chevronBackCircle, chevronBack,close, checkmark, eyeOff,eye,checkmarkDoneCircle, logoFacebook, logoGoogle, mail, at, lockClosed   } from 'ionicons/icons';


import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import {useStore} from "vuex";

import { Drivers, Storage } from '@ionic/storage'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonContent,
    IonPage,IonCol,IonRow
  },
  data() {
    return {
      isLast: false,
      swiper: Swiper,
      versionText: process.env.VUE_APP_VERSION,
      store : useStore(),
      storage : new Storage({
        name: '__jauserve',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
      logoFacebook, logoGoogle, mail
    }
  },
    created() {

      menuController.swipeGesture(false);

      this.storage.create();

      (async () => {

        const res = await this.storage.get('user');
        if(res){
          this.autoLogin()
        }

      })();

  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
   methods: {
    onSwiper(swiper:any) {
      this.swiper = swiper;
    },
    toEnd() {
      this.swiper.slideNext();
    },
    goLogin() {
      this.$router.push("/login")
    },
    goSingUp() {
      this.$router.push("/cadastro")
    },
    goGuest() {
      this.store.state.user.isGuest = true
      this.store.state.user.isBemvindo = false
      this.$router.push("/mercado")
    },
    autoLogin() {

      if(this.store.state.user.isFirst==true){
        this.$router.push("/login")
      }

    }
  },
});

