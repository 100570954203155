
import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonInput,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  menuController,
  IonApp,
  IonIcon,
  IonLabel,
  IonCol,
  IonRow,
  IonMenuToggle,
  IonAlert,
  IonLoading,
  IonToast,
  IonItem,
  IonText,
  IonButton,
  loadingController,
  IonModal,
  toastController,
  alertController
} from '@ionic/vue';

import { cart,person, chevronBackCircle, chevronBack,close, checkmark, eyeOffOutline,eyeOutline, eyeOff, eye, checkmarkDoneCircle, logoFacebook, logoGoogle, mail, at, lockClosed, logoApple   } from 'ionicons/icons';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import useVuelidate from '@vuelidate/core'
import { required,  minLength,
  maxLength,
  sameAs } from '@vuelidate/validators'

import { Capacitor } from '@capacitor/core';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { Drivers, Storage } from '@ionic/storage';

import { uuid } from 'vue-uuid';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import { StatusBar, Style } from '@capacitor/status-bar';
import maska from 'maska'

import VOtpInput from 'vue3-otp-input';

import {cpf as validarCPF, cnpj as validarCNPJ  } from 'cpf-cnpj-validator';

import {
  FacebookLogin,
  FacebookLoginResponse,
} from '@capacitor-community/facebook-login';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

const FACEBOOK_PERMISSIONS = [
  'email',
  'user_photos',
];

import SelectDia  from '../components/SelectDia.vue'

import { event } from 'vue-gtag'

import VueCountdown from '@chenfengyuan/vue-countdown';

const isEmailTaken = (value:any) => {
 // return this.cadastro.password;
} // check the email in the server

@Options({components: {IonPage,IonContent, IonCol, IonRow, IonToast, IonLoading, IonText, IonList, IonItem, IonInput, IonLabel, IonToolbar, IonTitle, maska, menuController, IonIcon, IonButton,IonAlert,IonModal,    Swiper, toastController, alertController,
    SwiperSlide, VOtpInput, useVuelidate, SelectDia, VueCountdown },
    validations: {
      cadastro: {
        password: { required,
          valid: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsLowercase = true///[a-z]/.test(value)//travei pra passar
            const containsNumber = /[0-9]/.test(value)
            //const containsSpecial = /[#?!@$%^&*-]/.test(value)
            return containsUppercase && containsLowercase && containsNumber /*&& containsSpecial*/ && value.length >= 8
          },
          hasnumber: function(value:any) {
            const containsNumber = /[0-9]/.test(value)
            return  containsNumber
          },
          hasupper: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            return  containsUppercase
          },
          minLength: minLength(8),
          maxLength: maxLength(19),
        },
        cpf: { required,
          valid: function(value:any) {

            if(value.length >14){
              return validarCNPJ.isValid(value);
            }else{
              return validarCPF.isValid(value);
            }

          }
        },
      },
    }



  })
export default class Login extends Vue {

  storage = new Storage({
    name: '__jauserve',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });

  usernames = "";
  passwords = "";
  cpf = "";

  CPF_tentativas = 3
  CPF_error = ""
  refresh="";

  submitted = false;
  isOpenRef = false;

  show_unificar = true;

  isOpenRefCode = false;
  isOpenRefCodeEmail = false;

  isOpenLoading = false;
  capturaCPF = false;
  capturaCELULAR = false;
  capturaCodUnico = false
  capturaNovaSenha = false
  capturaALL = false

  updateApp = false
  updateApp_info = false

  passwordType = true;
  passwordTypeConf = true;

  login_cfg  = { facebook:false, google:false, apple: false, mail:false };

  swiper = Swiper;

  swiperSl = Swiper

  setControlledSwiper = (swiper:any) => {
      this.swiperSl = swiper;
  };

  uuid=uuid.v1()
  pincode = { pin_1 : "",pin_2 : "",pin_3 : "",pin_4 : "",pin_5 : "",pin_6 : "" };

  cadastro = { nome:"", celular:"", pincode:{}, pin: false, uuid:"", cpf : "",  cpf_v : "",  password:"", password_conf : "", nascimento:"1990-01-01", dia_nascimentos:1, dia_nascimento:"0001-01-01T00:0:00-00:00", mesano_nascimento:"1990-01-01T00:0:00-00:00" };

  v$ = useVuelidate()




  get username():string {
    return this.usernames
  }

  get usernameValid() {
    return this.usernames!="";
  }
  get passwordValid() {
    return this.passwords!="";
  }

  set username(value) {
    this.usernames = value
  }
  onSwiper(swiper:any) {
      this.swiper = swiper;
  }

  showLoading = async () => {

    const loading = await loadingController.create({
      message: '',
      duration: 4000
    });

    loading.present();

  }

  store = useStore();



  atualizarApp() {

      //const PLAY_STORE_LINK = 'market://details?id=' + res[0].appid;

      
  }


  gotoCadastro() {
    this.isOpenLoading = false;
    this.$router.push({path:'/cadastro', query : { cpf: this.cadastro.cpf }});
  }

  goto() {
    this.isOpenLoading = false;
    this.$router.push('/login');
  }

  voltar() {
    this.isOpenLoading = false;
    this.$router.push('/bemvindo');
  }

  initPushNotifications() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      //alert('Push registration success, token: ' + token.value);
      this.store.state.user.push_token = token.value;

      fintailapi
      .post('/user/push', { "push": token.value, "id": this.store.state.user.id  })
      .then((response:any) => {


      })

    });

    PushNotifications.addListener('registrationError', (error: any) => {
      //alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        //alert('Push received: ' + JSON.stringify(notification));
        this.inAppNotification(notification.title, notification.body)
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //alert('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }

  handleOnComplete (value: string){

      this.pincode.pin_1 = value[0]
      this.pincode.pin_2 = value[1]
      this.pincode.pin_3 = value[2]
      this.pincode.pin_4 = value[3]
      this.pincode.pin_5 = value[4]
      this.pincode.pin_6 = value[5]

  }



  inAppNotification(title:any, message:any) {

      alertController.create({
        header: title,
        message: message,
        buttons: ['OK'],
      }).then((response:any) => {
        response.present();
      });

	}

  processLogin(data:any) {

        this.isOpenLoading = false;

        this.refresh =  data.refreshToken ;

        this.storage.set('user', { "email": this.usernames, "password": this.passwords, "refresh": data.refreshToken  });

        this.store.state.user.id  = data.info.id;
        this.store.state.user.info  = data.info;
        this.store.state.user.token = data.token;
        this.store.state.basket     = data.basket;

        this.store.state.user.isAuthenticated = true;
        this.store.state.user.isGuest = false
        this.store.state.user.isBemvindo = false

        fintailapi.defaults.headers.common['Authorization'] = data.token;

        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

        if (isPushNotificationsAvailable) {
            //aqui ativa a notifipush
            this.initPushNotifications();
        }
 
        this.cadastro.nome = data.info.first_name + " " + data.info.last_name


        if( data.info.needcpf ){

          this.capturaALL = true;

        }else if( data.info.needname ){

          this.capturaALL = true;

        }else if( data.info.needcpf ){

          this.capturaCPF = true;

        }else if( data.info.needcelular ){

          this.capturaCELULAR = true;

        }else{

          this.isOpenLoading = false;
          setTimeout(() => {
            this.$router.push('/');
          }, 300);

        }

  }

  onPinCode() {

    this.isOpenLoading = true;

    fintailapi
    .post('/user/logincode', { "email": this.usernames, "uuid": this.uuid,"pincode": this.pincode  })
    .then((response:any) => {

        const data = response.data
        if(data.success){

          this.swiper.slideNext();

          if(data.info){
            this.capturaCodUnico = false;
            this.processLogin(response.data);
          }else{
            this.isOpenLoading = false;
          }

        }else if(data.email){

          this.isOpenRefCodeEmail = true;
          this.isOpenLoading = false;
          this.capturaCodUnico = false;

        }else if(data.pincode){

          this.isOpenLoading = false;
          this.isOpenRefCode = true;

        }

    })
    .catch((error) => {

      this.isOpenLoading = false;
      this.isOpenRef = true;

      })

  }



  goNext(){
    this.swiperSl.slideNext();
  }

  goCPF(){

    let isValid_CPF = false
    if(this.cadastro.cpf_v.length >14){
      isValid_CPF = validarCNPJ.isValid(this.cadastro.cpf_v);
    }else{
      isValid_CPF = validarCPF.isValid(this.cadastro.cpf_v);
    }

    if(!isValid_CPF){
      this.CPF_error = "CPF inválido"
      return""
    }else{
      this.CPF_error =""
    }

    this.isOpenLoading = true;

    fintailapi
    .post('/user/validate/cpf', { "cpf_v":this.cadastro.cpf_v, "cpf": this.cadastro.cpf })
    .then((response:any) => {

      this.isOpenLoading = false;

      const data = response.data

      if(!data.success){

          this.swiperSl.slideNext();

          setTimeout(() => {
            this.show_unificar=false
          }, 60000);

        }else{
          this.gotoCadastro();
      }

    })
    .catch((error) => {


      if(this.CPF_tentativas > 0){

        setTimeout(() => {
          this.CPF_tentativas--
          this.goCPF()
        }, 1000);

      }else{
        
        this.isOpenLoading = false;
        this.isOpenRef = false;
        
        this.CPF_tentativas = 3

        alertController.create({
          header: 'Atenção',
          message: "Verifique sua internet e tente novamente",
          buttons: ['OK'],
        }).then((response:any) => {
          response.present();
        });
        
      }




    })


  }

  onLogin() {

    this.isOpenLoading = true;

    fintailapi
    .post('/user/login', { "email": this.usernames, "password": this.passwords  })
    .then((response:any) => {
      const data = response.data
      if(data.success){
        this.processLogin(data);
      }else{

        this.isOpenLoading = false;
        alertController.create({
          header: 'Atenção',
          message: data.error,
          buttons: ['OK'],
        }).then((response:any) => {
          response.present();
        });

      }

    })
    .catch((error) => {

      this.isOpenLoading = false;
      this.isOpenRef = true;

    })

  }

  onLoginRefresh() {

    this.isOpenLoading = true;

    fintailapi
    .post('/user/refresh', { "refresh": this.refresh  })
    .then((response:any) => {
      const data = response.data
      if(data.success){
        this.isOpenLoading = false;
        this.processLogin(data);
      }else{

        this.isOpenLoading = false;
        alertController.create({
          header: 'Atenção',
          message: data.error,
          buttons: ['OK'],
        }).then((response:any) => {
          response.present();
        });

      }

    })
    .catch((error) => {

      this.isOpenLoading = false;
      this.isOpenRef = true;

    })

  }

  onPinLostCode() {

    this.isOpenLoading = true;

    fintailapi
    .post('/user/lostpassword', { "email": this.usernames, "uuid": this.uuid,"pincode": this.pincode, "new_pass" : this.cadastro.password, "new_pass_conf" : this.cadastro.password_conf  })
    .then((response:any) => {

        const data = response.data
        if(data.success){

          this.swiper.slideNext();

          if(data.login){


            const toast = toastController.create({
              message: 'Sua senha foi alterada com sucesso, aguarde enquanto redirecionamos você...',
              duration: 4500,
              cssClass: 'custom-toast',
              position: 'middle',
              icon: checkmarkDoneCircle
            }) .then((response:any) => {

              response.present();

            });

            this.isOpenLoading = false;
            this.passwords = this.cadastro.password;
            this.capturaNovaSenha = false;
            this.onLogin();

          }else{

            this.isOpenLoading = false;

          }

        }else if(data.email){

          this.isOpenRefCodeEmail = true;
          this.isOpenLoading = false;
          this.capturaCodUnico = false;

        }else if(data.pincode){

          this.isOpenLoading = false;
          this.isOpenRefCode = true;

        }else{
          this.capturaNovaSenha = false;
          this.isOpenRefCodeEmail = true;
          this.isOpenLoading = false;
          this.capturaCodUnico = false;
        }

  })
  .catch((error) => {

    this.isOpenLoading = false;
    this.isOpenRef = true;

    })

  }


  goGuest() {
      this.store.state.user.isGuest = true
      this.store.state.user.isBemvindo = false
      this.$router.push("/mercado")
  }

  onLoginFacebook() {

      const result = FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }).then((result:any) => {
        if(!result)return;
        if (result.accessToken) {

          this.isOpenLoading = true;
          localStorage.setItem('facebookLogin', JSON.stringify(result) );

          fintailapi
          .post('/user/facebook', { "token": result.accessToken.token  })
          .then((response:any) => {
            const data = response.data
            if(data.success){

              this.processLogin(data);

            }else{

              this.isOpenLoading = false;

              alertController.create({
                header: 'Atenção',
                message: data.error || "Verifique sua conexão e tente novamente!",
                buttons: ['OK'],
              }).then((response:any) => {
                response.present();
              });

            }

          })
          .catch((error) => {


            this.isOpenLoading = false;

            alertController.create({
              header: 'Atenção',
              message: error.response.data.message || "Verifique sua conexão e tente novamente!",
              buttons: ['OK'],
            }).then((response:any) => {
              response.present();
            });
  
          })

        }else{

          alertController.create({
            header: 'Atenção',
            message: "Não foi possivel entrar usando Facebook tente novamente se necessário!",
            buttons: ['OK'],
          }).then((response:any) => {
            response.present();
          });


        }
      })
  }


  onLoginGoogle() {


    const user =  GoogleAuth.signIn().then((result:any) => {
      if(!result)return;
      if (result.authentication.accessToken) {

        this.isOpenLoading = true;
        localStorage.setItem('googleLogin', JSON.stringify(result) );

        fintailapi
        .post('/user/google', { "info":result, "token": result.authentication.accessToken  })
        .then((response:any) => {
          const data = response.data
          if(data.success){

                this.processLogin(data);

              }else{

                this.isOpenLoading = false;

                alertController.create({
                  header: 'Atenção',
                  message: data.error || "Verifique sua conexão e tente novamente!",
                  buttons: ['OK'],
                }).then((response:any) => {
                  response.present();
                });

              }

            })
            .catch((error) => {

              this.isOpenLoading = false;


              alertController.create({
                header: 'Atenção',
                message: error.response.data.message || "Verifique sua conexão e tente novamente!",
                buttons: ['OK'],
              }).then((response:any) => {
                response.present();
              });
  
              //this.isOpenLoading = false;
              // this.isOpenRef = true;

            })

          }else{

            alertController.create({
              header: 'Atenção',
              message: "Não foi possível entrar usando Google tente novamente se necessário!",
              buttons: ['OK'],
            }).then((response:any) => {
              response.present();
            });


          }
      }) .catch((error) => {

        alertController.create({
          header: 'Atenção',
          message: "Não foi possível entrar usando Google tente novamente se necessário!",
          buttons: ['OK'],
        }).then((response:any) => {
          response.present();
        });

      })
  }


  onLoginApple() {

    let options: SignInWithAppleOptions = {
      clientId: 'br.com.fintail.jauserve',
      redirectURI: 'https://localhost:8081/login',
      scopes: 'email name',
      state: '12345',
      nonce: 'nonce',
    };

    SignInWithApple.authorize(options)
    .then((result: SignInWithAppleResponse) => {
      if(!result)return;
      if (result.response.authorizationCode) {

        localStorage.setItem('appleLogin', JSON.stringify(result) );

        this.isOpenLoading = true;

        fintailapi
        .post('/user/apple', {  "info":result,  "token": result.response })
        .then((response:any) => {
          const data = response.data
          if(data.success){

                this.processLogin(data);

              }else{

                this.isOpenLoading = false;

                alertController.create({
                  header: 'Atenção',
                  message: data.error || "Verifique sua conexão e tente novamente!",
                  buttons: ['OK'],
                }).then((response:any) => {
                  response.present();
                });

              }

            })
            .catch((error) => {

              this.isOpenLoading = false;

              alertController.create({
                header: 'Atenção',
                message: error.response.data.message || "Verifique sua conexão e tente novamente!",
                buttons: ['OK'],
              }).then((response:any) => {
                response.present();
              });

            })

          }else{

            alertController.create({
              header: 'Atenção',
              message: "Não foi possivel entrar usando Apple tente novamente se necessário!",
              buttons: ['OK'],
            }).then((response:any) => {
              response.present();
            });


          }

      // Handle user information
      // Validate token with server and create new session
    })
    .catch(error => {
        alertController.create({
          header: 'Atenção',
          message: "Não foi possivel entrar usando Apple tente novamente se necessário!",
          buttons: ['OK'],
        }).then((response:any) => {
          response.present();
        });
    });

  }


  reSendValidacao() {

    this.isOpenLoading = false;
    this.isOpenLoading = false;
    this.callValidacao();

  }

  callValidacao() {


    if(this.cadastro.celular == ""){
        return;
    }

    this.isOpenLoading = true;
    this.cadastro.pincode =  this.pincode;

    fintailapi
    .post('/user/validate', this.cadastro)
    .then((response:any) => {

        const data = response.data
        this.cadastro.uuid = data.uuid

        this.cadastro.pin = true;

        this.isOpenLoading = false;

    })
    .catch((error) => {
    })

  }


  updateNovosDados() {

    this.isOpenLoading = true;
    this.cadastro.pincode =  this.pincode;

    


    fintailapi
    .patch('/user/me',  this.cadastro )
    .then((response:any) => {

      this.isOpenLoading = false;

      const data = response.data

      if(data.success){

        this.capturaCPF = false;
        this.capturaCELULAR = false;
        this.capturaALL = false;

        this.onLoginRefresh()

      }else{

        const toast = toastController.create({
              message: 'Código inválido, por favor verifique se o código digitado esta correto',
              duration: 3500,
              cssClass: 'custom-toast',
              position: 'middle',
              icon: checkmarkDoneCircle
            }) .then((toasts:any) => {

              toasts.present();

            });

      }

    })
    .catch((error) => {
        this.goto();
    })

  }

  created() {

    event('login', { method: 'Google' })

    GoogleAuth.initialize({
      clientId: '360530463032-vm9pjlc45usrk9nu1vlpo3gddv067ped.apps.googleusercontent.com',
      grantOfflineAccess: true,
    });

    this.store.state.user.isFirst = false

    this.storage.create();


    (async () => {


      fintailapi
      .post('/configuracao', { "app" : "jauserve", "device": Capacitor.getPlatform() })
      .then((response:any) => {
          const data = response.data
          if(data.success){

            this.login_cfg = data.login

            if(data.need_update.status){

              this.updateApp = true
              this.updateApp_info = data.need_update

            }

          }
      })
      //await StatusBar.show();

      const res = await this.storage.get('user');
      if(res){

        this.usernames = res.email;
        this.passwords = res.password;

        localStorage.setItem('reOpen', 'true');

        if(res.refresh){
          this.refresh = res.refresh;
          this.onLoginRefresh()
        }else if(this.usernames && this.passwords){
          this.onLogin()
        }
      }

    })();

    menuController.swipeGesture(false);

  }
  data() {
    return {
      chevronBackCircle,
      chevronBack,
      close,
      checkmark,
      eyeOff,
      eye,
      eyeOffOutline,
      eyeOutline,
      logoFacebook,
      logoGoogle,
      mail,
      logoApple,
      person,
      at,
      lockClosed

    }
  }

}

