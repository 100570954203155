
import {   IonPage, IonContent , 
  IonHeader, 
  IonItem, 
  IonMenu, 
  IonRouterOutlet, 
  IonApp, 
  IonList, 
  IonLabel, 
  IonListHeader, 
  IonMenuToggle,
  IonToolbar,
IonButton, IonFooter,
  IonIcon,
  IonSegmentButton,
  IonSegment,
  IonTitle,
  IonButtons,
  IonBackButton } from '@ionic/vue';
import { call, person, settings } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { IonContent, 
        IonPage, 
        IonItem, 
        IonList, 
        IonMenu, 
        IonButton,
        IonTitle, 
        IonToolbar, IonSegmentButton,
  IonSegment,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonButtons,
        IonBackButton },
  setup() {
    return { call, person, settings }
  }
});
