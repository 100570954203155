


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonCol,
  IonButton,
  IonFooter,
  IonBadge,
  IonSkeletonText,
  IonButtons,
  IonNote,
  IonThumbnail,
  IonIcon,
  IonTitle,
  IonModal,
  IonBackButton,
  IonRow,
  IonSpinner,
  IonTextarea,
  alertController
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import moment from 'moment';

import { ref } from 'vue';

import 'swiper/swiper-bundle.css'

import Pedido from '../components/Pedido.vue'
import  SafeArea  from '../components/SafeArea.vue'



Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonBadge,
        IonApp,
        IonModal,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonHeader,
        IonCol,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        Swiper,
        SwiperSlide,
        IonRow,
        IonSkeletonText,
        IonButtons,
        IonNote,
        IonThumbnail,
        IonTextarea,
        Pedido,
        SafeArea,
        IonSpinner,
        alertController
  },
  computed: {
      carts() {
        return this.$store.getters.carts
      },
      cartItemNumber() {
        return this.$store.getters.cartItemNumber
      },
      user(){
        return this.$store.state.user
      }
  },
  methods: {
      cancel() {
         this.$refs.modal.$el.dismiss();
      }
    },
 modules: [Pagination]
 })

export default class Pedidos extends Vue {

  meuspedidos = {
    pedidos : [],
    selecionado:{ customer_info:{ customer_id:{} }, order_no: "", current_rating : { nota_geral : 0, nota_entrega : 0, nota_aplicativo:0, comentario : ""  },  shipments:[{shipping_address:""}]},
    baseURL: "",
    avaliacao: Math.floor(Math.random() * 5)
  };

  store = useStore();
  iconVoltar  = allIcons.chevronBack
  iconRemove  = allIcons.trashOutline
  iconSave    = allIcons.saveOutline
  iconOferta  = allIcons.pricetag
  iconFechar  = allIcons.close

  iconDetalhes =  allIcons.pricetag
  iconAvaliacao =  allIcons.star
  iconEndereco =  allIcons.map
  iconPay =  allIcons.card
  iconResumo =  allIcons.reader

  iconStar = allIcons.star

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline


  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  isOpenRef = false;
  isOpenAtivacao = true;

  isOpenAvaliacao = false;

  modules = [Pagination];

  loadingPedidos = true;
  loadingAvaliar = false;

  setOpen = (state: boolean) => this.isOpenRef = state;

  presentAlert = async (titulo:any, subHeader:any, message:any) => {
        const alert = await alertController.create({
          header: titulo,
          subHeader: subHeader,
          message: message,
          buttons: ['OK'],
        });

        await alert.present();
  }

  meusPedidos = async () => {

    fintailapi
    .post('/user/pedidos', {"user_id" : this.store.state.user.id})
    .then((response:any) => {

      this.loadingPedidos = false;

      let data = response.data;
      if (data.success) {

			  this.meuspedidos.pedidos = data.orders;
        this.meuspedidos.baseURL = fintailapi.defaults.baseURL || ""
			}

    });


  }

  sendAvaliacao = async () => {
    this.loadingAvaliar=true;
    fintailapi
    .post('/user/pedidos/avaliar', { "order" : {"client_id" : this.meuspedidos.selecionado.customer_info.customer_id,  "order_id": this.meuspedidos.selecionado.order_no, "rating": this.meuspedidos.selecionado.current_rating }})
    .then((response:any) => {

      this.loadingAvaliar = false;

      let data = response.data;
      if (data.success) {

        this.meusPedidos();

        this.isOpenAvaliacao=false;

        this.presentAlert("Avaliação", "Recebemos sua avaliação"," Obrigado, sua avalição foi feita com sucesso!");

        this.setOpen(false);

      }

    });


  }

  toHora(value:any) {
    if(!value)return "";
    return moment(value).format('HH')+"h";
  }

  toDia(value:any) {
        if(!value)return "";
    return moment(value).format('DD');
  }

  toMese(value:any) {
        if(!value)return "";
    return moment(value).format('MMM');
  }

  toDiaMes(value:any) {
    if(!value)return "";
    return moment(value).format('DD/MM');
  }

  voltar() {
    this.$router.push("/")
  }

  pedidoSelecionado(order:any) {

    this.setOpen(true);
    this.meuspedidos.selecionado = order;

    //this.meuspedidos.selecionado.current_rating = { nota_geral : 0, nota_entrega : 0, nota_aplicativo:0, comentario : ""  }

  }

  created() {
    moment.locale("pt-br");
    this.meusPedidos()
  }

  beforeRouteLeave(to:any, from:any, next:any){

    this.isOpenAtivacao = false;

    this.isOpenRef = false;
    this.isOpenAtivacao = true;

    this.loadingPedidos = true;
    this.loadingAvaliar = false;

    next();
  }

}

