
import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  IonLabel,
  IonRadio,
  IonToggle,
  IonHeader,
  IonModal,
  IonPage,
  IonRow,
  IonBadge,
  IonToolbar,
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,IonCol,
  IonTitle,
  IonButtons,
  IonSpinner,

  menuController
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';
import IconDescontos from '../components/icons/IconDescontos.vue'

import {  loadingController } from '@ionic/vue';

import { Options , Vue} from 'vue-class-component';

import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css"

import { Drivers, Storage } from '@ionic/storage'

import Campanha from '../components/Campanha.vue'

import moment from 'moment';

import FooterMenu from '../components/FooterMenu.vue'


import { StatusBar, Style } from '@capacitor/status-bar';

@Options({components: { IonModal, Swiper, SwiperSlide, IonHeader, IonPage,  IonContent, IonGrid, IonFooter, IonIcon,  IonCol, IonBadge, IonList, IonRow, IonToolbar, IonTitle, IonButtons, IonButton, Campanha,IonSpinner,IonItem,IonLabel,FooterMenu },
  computed: {
    carts() {
      return this.$store.getters.carts
    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
}})
export default class Dashboard extends Vue {

  storage = new Storage({
    name: '__jauserve',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });


  descontoIcon = "/assets/icon/assets0005.svg";

  toHora(value:any) {
    if(!value)return "";
    return moment(value).format('HH')+"h";
  }

  toDia(value:any) {
        if(!value)return "";
    return moment(value).format('DD');
  }

  toMese(value:any) {
        if(!value)return "";
    return moment(value).format('MMMM');
  }

  toDiaMes(value:any) {

    if(!value)return "";

    const today = moment().startOf('day');
    const yourDate = moment(value);

    if (yourDate.isSame(today, 'day')) {
      return "Hoje"
    } else if (yourDate.isSame(today.clone().add(1, 'day'), 'day')) {
      return "Amanhã"
    } else {
      return moment(value).format('DD/MM');
    }


    return moment(value).format('DD/MM');

  }

  showCampanha(campanha: any) {
    if(campanha.urlparticipacao=="")return;
    this.campanhas.selecionada = campanha;
    this.campanhas.show = true;

  }


 setOpen(isOpen: boolean) {
    this.isOpen = isOpen;
  }

  openMenu() {
    menuController.open('app-menu');
  }

  closeBanner(isOpen: boolean) {
    this.isOpen = isOpen;
    this.state.banner = false;
  }

  showLoading = async () => {

      const loading = await loadingController.create({
        message: '',
        duration: 4000
      });

      loading.present();

  }


  total_notificacoes = 0

  state = useStore().state

  user_info = this.state.user.info

  isOpen = false;

  push_banner = {status:false, text:"", image: "" };

  banners = [
    { ordem: 0, url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAFNAQMAAADM3Q90AAAAA3NCSVQICAjb4U/gAAAABlBMVEX///////9VfPVsAAAAAnRSTlMA/1uRIrUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMjkvMjIAisaqAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAACxJREFUeJztwTEBAAAAwqD1T20ND6AAAAAAAAAAAAAAAAAAAAAAAAAAAODXAFNAAAGZLaipAAAAAElFTkSuQmCC" }
  ];

  campanhas = {
    show: false,
    iframe: {show:false, url:"#" },
    selecionada: {},
    campanhas: { sorteio:[], promocional : <any> [] }
  };


  ofertas = [
    { ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" }
  ];


  pedido = {
    show : false,
    payment_status : ''
  };

  iconInicio    = allIcons.homeOutline
  iconSecoes    = allIcons.gridOutline
  iconPromocoes = allIcons.bagHandleOutline
  iconLojas     = allIcons.storefrontOutline
  iconFechar     = allIcons.close
  iconVoltar     = allIcons.chevronBack
  icon_user      = allIcons.personCircle
  icon_chat      = allIcons.chatbox
  icon_right     = allIcons.chevronForward
  icon_churras   = allIcons.restaurant
  icon_recarga   = allIcons.phonePortrait
  icon_qr        = allIcons.qrCode

  iconTab = allIcons.newspaperOutline

  icon_home = allIcons.home

  icon_cart = allIcons.cart
  icon_bag = allIcons.bag

  icon_menu = allIcons.list

  icon_message = allIcons.mailOutline 


  icon_order = allIcons.fileTray

  icon_promo = allIcons.pricetag

  icon_map = allIcons.map

  modules = [Autoplay, Pagination, Navigation]

  store = useStore();

  dashboardInfo = async () => {




    const location = await this.storage.get('location')

    if (location){

      this.store.state.user.geolocation = location
      fintailapi.defaults.headers.common['Store'] = location.codigo_loja

    }




    fintailapi
    .post('/dashboard', {})
    .then((response:any) => {

      let data = response.data;
      if (data.success) {

        this.banners = data.banners;

        if(data.campanhas){

          this.campanhas.campanhas = data.campanhas;
          this.campanhas.campanhas.promocional.push({"nome" : "Fique ligado nas ofertas Jaú Serve", "urlparticipacao":"" })

        }else{
          this.campanhas.campanhas.promocional.push({"nome" : "Fique ligado nas ofertas Jaú Serve", "urlparticipacao":"" })
        }

        this.total_notificacoes = data.notificacoes.nao_lidas;

        this.store.state.notification = data.notificacoes;
 
        this.storage.set('banners', data.banners);
        this.storage.set('ofertas', data.ofertas.ofertas);


        if(this.state.banner && data.pushbanner.status){
          this.push_banner=data.pushbanner;
          this.isOpen = true;
        }


			}

    });

  }

  navigate( url : string) {
    this.$router.push(url)
  }

  created() {

    if(this.store.state.order!=undefined && this.store.state.order.final){

      this.pedido = this.store.state.order;
      this.pedido.show=true;

    }

    this.storage.create();
    this.dashboardInfo()
  }



}

