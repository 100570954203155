import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueScrollPicker = _resolveComponent("VueScrollPicker")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_row = _resolveComponent("ion-row")!

  return (_openBlock(), _createBlock(_component_ion_row, { class: "ion-align-items-center no-padding" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_col, {
        size: "2",
        class: "ion-text-center no-padding"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_VueScrollPicker, {
            options: _ctx.options,
            modelValue: _ctx.currentValue,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentValue) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => {_ctx.data.dia_nascimentos=_ctx.currentValue;_ctx.changeDt()})
            ]
          }, null, 8, ["options", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_col, {
        size: "10",
        class: "ion-text-center no-padding"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_datetime, {
            modelValue: _ctx.data.mesano_nascimento,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.mesano_nascimento) = $event)),
            presentation: "month-year",
            value: "1990-01-01T13:47:20.789",
            onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeDt())),
            onIonInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cadastro.mesano_nascimento=$event.target.value))
          }, null, 8, ["modelValue", "value"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}