


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonCol,
  IonRow,
  IonNote,
  IonListHeader,
  IonMenuToggle,
  IonSkeletonText,
  IonToolbar,
  IonButton,
  IonButtons,
  IonAvatar,
  IonFooter,
  IonIcon,
  IonSpinner,
  IonTitle,
  IonModal,
  IonBackButton
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import moment from 'moment';

import { ref } from 'vue';

import 'swiper/swiper-bundle.css'
import SafeArea  from '../components/SafeArea.vue'

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonButton,
        IonButtons,
        IonTitle,
        IonHeader,
        IonToolbar,
        IonSkeletonText,
        IonCol,
        IonNote,
        IonRow,
        IonApp,
        IonAvatar,
        IonModal,
        IonIcon,
        IonLabel,
        IonSpinner,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        Swiper,
        SwiperSlide,
        SafeArea,
  },
  computed: {
      carts() {
        return this.$store.getters.carts
      },
      cartItemNumber() {
        return this.$store.getters.cartItemNumber
      },
      user(){
        return this.$store.state.user
      }
  },
  methods: {
      cancel() {
         this.$refs.modal.$el.dismiss();
      }
    },
 modules: [Pagination]
 })

export default class Pedidos extends Vue {

  minhaslistas = {
    listas : [
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
      { loading:true, validade:' ', items_link:{}, imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    ],
    selecionado: { items:[], shipments : [{ shipping_address : "" }]}
  };

  store = useStore();

  iconRemove  = allIcons.trashOutline
  iconSave    = allIcons.saveOutline
  iconOferta  = allIcons.pricetag
  iconFechar  = allIcons.close
  iconVoltar  = allIcons.chevronBack
  iconAvancar  = allIcons.chevronForward

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove
  iconTrash  = allIcons.trash

  isOpenRef = false;
  isOpenAtivacao = true;

  setOpen = (state: boolean) => this.isOpenRef = state;

  minhasListas = async () => {

    fintailapi
    .post('/user/lista', {"user_id" : this.store.state.user.id})
    .then((response:any) => {

      let data = response.data;
      if (data.success) {

			  this.minhaslistas.listas = data.lists;

			}

    });


  }

  navigate( url : string) {
    this.setOpen(false);
    this.$router.push(url)
  }


  toSugetion(){

    this.setOpen(false);
    const lista_sugestoes =  this.minhaslistas.selecionado.items.map((elem:any) => { return  elem.id_produto });
    this.store.state.user.sugestoes = lista_sugestoes.join(",");
    this.$router.push('/sugestoes');

  }


  toDia(value:any) {
    if(!value)return "";
    return moment(value).format('DD');
  }

  toMese(value:any) {
    if(!value)return "";
    return moment(value).format('MMM');
  }

  toDiaMes(value:any) {
    if(!value)return "";
    return moment(value).format('DD/MM');
  }
  getImage(value:any) {

    return "https://api.fintail.com.br/api/produto/image/"+value.id_produto
  }

  voltar() {
    this.$router.back()
  }

  showLista(lista:any) {
    this.setOpen(true);
    this.minhaslistas.selecionado = lista;
  }

  removeLista(lista:any){
    lista.remove = true;
    fintailapi
    .delete('/user/lista',{ data: {"uuid" : lista.uuid} })
    .then((response:any) => {

      let data = response.data;
      if (data.success) {

        this.minhaslistas.listas = data.lists;

      }

    });


  }

  created() {
    moment.locale("pt-br");
    this.minhasListas()
  }

}

