


  import {
    IonPage,
    IonContent ,
    IonItem,
    IonInput,
    IonLabel,
    IonApp,
    IonList,
    IonListHeader,
    IonMenuToggle,
    IonToolbar,
    IonButton,
     IonFooter,
    IonIcon,
    IonButtons,
    IonBackButton,
    IonRadio,
    IonModal,
    IonRadioGroup
  } from '@ionic/vue';

  import useVuelidate from '@vuelidate/core'
  import { required,  minLength,
    maxLength,
    sameAs, } from '@vuelidate/validators'

  import { defineComponent, ref } from 'vue';

  import { cart, chevronForward, chevronBack,helpCircle, checkmark, close } from 'ionicons/icons';

  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

  import { Pagination } from "swiper";

  import 'swiper/swiper-bundle.css'

  import { Drivers, Storage } from '@ionic/storage';

  import fintailapi from "../api";

  import {useStore} from "vuex";

  import { uuid } from 'vue-uuid';

  import maska from 'maska'

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
      IonFooter,
      IonButton,
      IonToolbar,
      IonIcon,
      IonContent,
      IonPage,
      IonItem,
      IonLabel,
      IonInput,
      maska,
      IonRadio,
      IonModal,
      IonRadioGroup,
      useVuelidate
    },
    data() {
      return {
        chevronForward,chevronBack, helpCircle, checkmark, close,
        isLast: false,
        isOpenAtivacao:true,
        erro_pin:"",
        recarga:{ uuid: uuid.v1(), sexo : 0, cpf : "", email:"", password:"" },
        validador:['cpf', 'celular','pin_1','nome','sexo','email','password','nascimento','privacidade','termosdeuso'],
        validador_tamanho:[10, 10, 1, 5, 1, 5, 8, 10, 1, 1],
        swiper: Swiper,
        store : useStore(),
        storage : new Storage({
          name: '__jauserve',
          driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
        }),
      }
    },
    setup() {
      return {
        v$: useVuelidate(),
        modules: [Pagination],
      };
    },
    validations () {
      return {
        //password: { required },
        //confirmPassword: sameAs(this.password),
        recarga: {
          password: { required,
            valid: function(value:any) {
              const containsUppercase = /[A-Z]/.test(value)
              const containsLowercase = /[a-z]/.test(value)
              const containsNumber = /[0-9]/.test(value)
              //const containsSpecial = /[#?!@$%^&*-]/.test(value)
              return containsUppercase && containsLowercase && containsNumber /*&& containsSpecial*/ && value.length >= 8
            },
            hasnumber: function(value:any) {
              const containsNumber = /[0-9]/.test(value)
              return  containsNumber
            },
            hasupper: function(value:any) {
              const containsUppercase = /[A-Z]/.test(value)
              return  containsUppercase
            },
            minLength: minLength(8),
            maxLength: maxLength(19),
          },
          confirmPassword: { required, sameAsPassword: sameAs("password") },
        },
      }
    },
     methods: {
      onSwiper(swiper:any) {
        this.swiper = swiper;
      },
      voltar() {
        this.$router.back()
      },
      toStart() {

        if( this.swiper.isBeginning){
          this.$router.back();
        }else
          this.swiper.slidePrev();

      },
      onLast() {
        this.isLast=true;
      },
      onFirst() {
         this.isLast=false;
      },
      toEnd() {

        const validador:string = this.validador[this.swiper.activeIndex];

        const Icon = (this.recarga as any)
        if(Icon[validador] == undefined || Icon[validador].length < this.validador_tamanho[this.swiper.activeIndex])return;


        if(validador == "celular"){

          this. callValidacao()

        }


        if(validador == "password"){

          if(!this.v$.recarga.password.valid.$invalid)this.swiper.slideNext();

        }else if(validador == "pin_1"){

          this.checkValidacao().then((response:any) => {

            const data = response.data
            if(data.success)this.swiper.slideNext();
            else this.erro_pin="PIN incorreto tente novamente"

          })
          .catch((error) => {
          })


        }else{

          this.swiper.slideNext();

        }



      },
       showPolitica() {
        this.isOpenAtivacao = false;
        this.isOpenAtivacao = true;
      },
       showTermos() {
        this.isOpenAtivacao = false;
        this.isOpenAtivacao = true;
      },
      callValidacao() {

        fintailapi
        .post('/user/validate', this.recarga)
        .then((response:any) => {

          const data = response.data
          this.recarga.uuid = data.uuid
          console.log(data);

        })
        .catch((error) => {
        })

      },
      checkValidacao() {

          return fintailapi.post('/user/validate', this.recarga)

      },
      callSignUP() {


          fintailapi
          .post('/user/new', this.recarga)
          .then((response:any) => {

              this.storage.set('user', { "email": this.recarga.email, "password": this.recarga.password });

              this.store.state.user.id  = response.data.info.id;
              this.store.state.user.info  = response.data.info;
              this.store.state.user.token = response.data.token;
              this.store.state.basket     = response.data.basket;

              this.store.state.user.isAuthenticated = true;
              this.store.state.user.isGuest = false
              this.store.state.user.isBemvindo = false

              //fintailapi.userID = 123;
              //fintailapi. = 11;
              fintailapi.defaults.headers.common['Authorization'] = response.data.token;

              this.$router.push('/');

          })
          .catch((error) => {



          })



      },
      goSingUp() {
        this.$router.push("/login")
      },
      goGuest() {

        this.store.state.user.isGuest = true
        this.$router.push("/mercado")

      },
    },
  });

  