


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonModal,
  IonTitle,
  IonButtons,
  IonBackButton,
  alertController,
  IonAccordion,
  IonCol,
  IonRow,
  IonAvatar,
  IonNote,
  IonAccordionGroup
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { defineComponent } from 'vue';
import  SafeArea  from '../components/SafeArea.vue'

import { Share } from '@capacitor/share';

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonModal,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonButtons,
        IonBackButton,
        alertController,
        SafeArea,
        Share,
        IonAccordion,
  IonCol,
  IonRow,
  IonAvatar,
  IonNote,
  IonAccordionGroup
}})

export default class Churrasco extends Vue {


  calculoChurrasco = false;

	churrascometro = {
    homens : 0, mulheres : 0, criancas : 0,  vegetarianos : 0,
    "secoes":[
          {"id" : 1, "descricao": "CARNE BOVINA", opcoes:[{"descricao":"Alcatra", "selecionado":false}, {"descricao":"Contra Filé", "selecionado":false}, {"descricao":"Costela Bovina", "selecionado":false}, {"descricao":"Filé Mignon", "selecionado":false}, {"descricao":"Fraldinha", "selecionado":false}, {"descricao":"Maminha", "selecionado":false}, {"descricao":"Picanha", "selecionado":false}]},
          {"id" : 2, "descricao": "CARNE SUINA", opcoes:[{"descricao":"Alcatra", "selecionado":false}, {"descricao":"Costela Suína", "selecionado":false}, {"descricao":"Linguiças frescas", "selecionado":false}, {"descricao":"Lombo", "selecionado":false}, {"descricao":"Picanha", "selecionado":false}]},
          {"id" : 3, "descricao": "CARNE OVINA", opcoes:[{"descricao":"Carré", "selecionado":false}, {"descricao":"Paleta", "selecionado":false}, {"descricao":"Pernil", "selecionado":false}, {"descricao":"Picanha de Cordeiro", "selecionado":false}]},
          {"id" : 4, "descricao": "CARNE DE FRANGO", opcoes:[{"descricao":"Coração", "selecionado":false}, {"descricao":"Coxa", "selecionado":false}, {"descricao":"Coxa da asa", "selecionado":false}, {"descricao":"Peito", "selecionado":false}, {"descricao":"Sobrecoxa", "selecionado":false}, {"descricao":"Tulipa de Frango", "selecionado":false}]},
          {"id" : 5, "descricao": "VEGETAIS", opcoes:[{"descricao":"Abobrinha", "selecionado":false}, {"descricao":"Berinjela", "selecionado":false}, {"descricao":"Brócolis", "selecionado":false}, {"descricao":"Cebola", "selecionado":false}, {"descricao":"Cenoura", "selecionado":false}, {"descricao":"Cogumelo", "selecionado":false}, {"descricao":"Pimentão", "selecionado":false}, {"descricao":"Tomate", "selecionado":false}]}
          ],
    "acompanhamentos":[
        {"descricao":"Arroz", "selecionado":false},
        {"descricao":"Farofa", "selecionado":false},
        {"descricao":"Maionese / Salada de Batata", "selecionado":false},
        {"descricao":"Pão de Alho", "selecionado":false},
        {"descricao":"Queijo Coalho", "selecionado":false},
        {"descricao":"Vinagrete", "selecionado":false}
    ],

    "suprimentos": [
        {"descricao":"Carvão", "selecionado":false},
        {"descricao":"Copos", "selecionado":false},
        {"descricao":"Gelo", "selecionado":false},
        {"descricao":"Guardanapos", "selecionado":false},
        {"descricao":"Limão", "selecionado":false},
        {"descricao":"Pratos", "selecionado":false},
        {"descricao":"Sal Grosso", "selecionado":false},
        {"descricao":"Talheres", "selecionado":false}
    ],
    "bebidas": [
        {"descricao":"Água", "selecionado":false},
        {"descricao":"Cachaça", "selecionado":false},
        {"descricao":"Cerveja", "selecionado":false},
        {"descricao":"Cervejas Especiais", "selecionado":false},
        {"descricao":"Refrigerante", "selecionado":false},
        {"descricao":"Suco", "selecionado":false},
        {"descricao":"Vinho", "selecionado":false},
        {"descricao":"Vodka", "selecionado":false}
    ],
		"selecionado" : {}, "ativos" : [], "carregando" : false, "resultado" : { carnes:[], acompanhamentos:[], suprimentos:[], bebidas:[], total:{carnes : { h : 0, m:0, c:0 }},homens : 0, mulheres : 0, criancas : 0,  vegetarianos : 0 }
  };



  padrao_churrasco_avancado = { ...this.churrascometro };

padrao_churrasco_tradicional = {
    homens : this.churrascometro.homens, mulheres : this.churrascometro.mulheres, criancas : this.churrascometro.criancas,  vegetarianos : 0,
    "secoes":[
          {"id" : 1, "descricao": "CARNE BOVINA", opcoes:[{"descricao":"Alcatra", "selecionado":true}, {"descricao":"Contra Filé", "selecionado":true}, {"descricao":"Costela Bovina", "selecionado":false}, {"descricao":"Filé Mignon", "selecionado":false}, {"descricao":"Fraldinha", "selecionado":true}, {"descricao":"Maminha", "selecionado":true}, {"descricao":"Picanha", "selecionado":true}]},
          {"id" : 2, "descricao": "CARNE SUINA", opcoes:[{"descricao":"Alcatra", "selecionado":false}, {"descricao":"Costela Suína", "selecionado":false}, {"descricao":"Linguiças frescas", "selecionado":true}, {"descricao":"Lombo", "selecionado":false}, {"descricao":"Picanha", "selecionado":false}]},
          {"id" : 3, "descricao": "CARNE OVINA", opcoes:[{"descricao":"Carré", "selecionado":false}, {"descricao":"Paleta", "selecionado":false}, {"descricao":"Pernil", "selecionado":false}, {"descricao":"Picanha de Cordeiro", "selecionado":false}]},
          {"id" : 4, "descricao": "CARNE DE FRANGO", opcoes:[{"descricao":"Coração", "selecionado":false}, {"descricao":"Coxa", "selecionado":true}, {"descricao":"Coxa da asa", "selecionado":true}, {"descricao":"Peito", "selecionado":false}, {"descricao":"Sobrecoxa", "selecionado":false}, {"descricao":"Tulipa de Frango", "selecionado":false}]},
          {"id" : 5, "descricao": "VEGETAIS", opcoes:[{"descricao":"Abobrinha", "selecionado":false}, {"descricao":"Berinjela", "selecionado":false}, {"descricao":"Brócolis", "selecionado":false}, {"descricao":"Cebola", "selecionado":false}, {"descricao":"Cenoura", "selecionado":false}, {"descricao":"Cogumelo", "selecionado":false}, {"descricao":"Pimentão", "selecionado":false}, {"descricao":"Tomate", "selecionado":false}]}
          ],
    "acompanhamentos":[
        {"descricao":"Arroz", "selecionado":true},
        {"descricao":"Farofa", "selecionado":true},
        {"descricao":"Maionese / Salada de Batata", "selecionado":false},
        {"descricao":"Pão de Alho", "selecionado":true},
        {"descricao":"Queijo Coalho", "selecionado":false},
        {"descricao":"Vinagrete", "selecionado":false}
    ],

    "suprimentos": [
        {"descricao":"Carvão", "selecionado":true},
        {"descricao":"Copos", "selecionado":false},
        {"descricao":"Gelo", "selecionado":false},
        {"descricao":"Guardanapos", "selecionado":false},
        {"descricao":"Limão", "selecionado":false},
        {"descricao":"Pratos", "selecionado":false},
        {"descricao":"Sal Grosso", "selecionado":false},
        {"descricao":"Talheres", "selecionado":false}
    ],
    "bebidas": [
        {"descricao":"Água", "selecionado":false},
        {"descricao":"Cachaça", "selecionado":false},
        {"descricao":"Cerveja", "selecionado":true},
        {"descricao":"Cervejas Especiais", "selecionado":false},
        {"descricao":"Refrigerante", "selecionado":true},
        {"descricao":"Suco", "selecionado":true},
        {"descricao":"Vinho", "selecionado":false},
        {"descricao":"Vodka", "selecionado":false}
    ],
		"selecionado" : {}, "ativos" : [], "carregando" : false, "resultado" : { carnes:[], acompanhamentos:[], suprimentos:[], bebidas:[], total:{carnes : { h : 0, m:0, c:0 }},homens : 0, mulheres : 0, criancas : 0,  vegetarianos : 0 }
  };



  iconVoltar = allIcons.chevronBack
  iconPlus = allIcons.add
  iconMinus = allIcons.remove
  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  icon_share = allIcons.share

  icon_lista = allIcons.receipt

  churrasco_tipo = 0;
  churrasco_avancado = false

  store = useStore();


 setOpen(isOpen: boolean) {
        this.calculoChurrasco = isOpen;
  }

	changeSelect(item:any) {

      item.selecionado=!item.selecionado;

	}

	changeValue(item:any, op:string) {


    const churrascometro = (this.churrascometro as any)

    //churrascometro[item]++;

		if(op == "+")churrascometro[item]++;
		if(op == "-" && churrascometro[item] > 0)churrascometro[item]--;

    this.churrascometro = churrascometro

	}


  setTradicional() {
    this.padrao_churrasco_tradicional.homens   = this.churrascometro.homens;
    this.padrao_churrasco_tradicional.mulheres = this.churrascometro.mulheres;
    this.padrao_churrasco_tradicional.criancas = this.churrascometro.criancas;

    this.churrascometro = this.padrao_churrasco_tradicional;
    this.churrasco_avancado=false
    this.churrasco_tipo=1
  }

  setAvancado() {

    this.padrao_churrasco_avancado.homens   = this.churrascometro.homens;
    this.padrao_churrasco_avancado.mulheres = this.churrascometro.mulheres;
    this.padrao_churrasco_avancado.criancas = this.churrascometro.criancas;

    this.churrascometro = this.padrao_churrasco_avancado;
    this.churrasco_avancado=true
    this.churrasco_tipo = 2
  }


  voltar() {
    this.$router.back()
  }

	kFormatter(e:any) {
		return Math.abs(e) > 999 ? ((Math.sign(e) * (Math.ceil(e) / 1000)) + " kg").replace(".", ",") : Math.sign(e) * Math.ceil(e) + " g"
	}
	lFormatter(e:any) {
		return Math.abs(e) > 999 ? (Math.sign(e) * (Math.ceil(e) / 1000) + " L").replace(".", ",") : Math.sign(e) * Math.ceil(e) + " mL"
	}

  alerta = async () => {
        const alert = await alertController.create({
          header: 'Listas de Compras',
          subHeader: 'Lista salva com sucesso',
          message: 'Sua lista de compras foi salva com sucesso, agora você pode consulta-la quando quiser no menu Listas de Compras na tela principal',
          buttons: ['OK'],
        });

        await alert.present();
  };

  goTranformar(){

  var currentTime = new Date()

		fintailapi
    .post('/user/churrascometro/salvar', { "customer_id": this.store.state.user.info.id, "lista":{ "nome": "Churrasco "+currentTime.getDate()+"/"+(currentTime.getMonth() + 1) }, "produtos": this.churrascometro.resultado })
		.then((response:any) => {

      let data = response.data;

      this.alerta()

		}, function(response) {

		});

  }

  async goShare(){


    let texto = "";


    console.log(this.churrascometro.resultado);


    this.churrascometro.resultado.carnes.forEach((t:any) => {
      texto = texto +t.calculo +" "+ t.descricao+"\n"
    })

    this.churrascometro.resultado.acompanhamentos.forEach((t:any) => {
      texto = texto +t.calculo +" "+ t.descricao+"\n"
    })

    this.churrascometro.resultado.suprimentos.forEach((t:any) => {
      texto = texto +t.calculo +" "+ t.descricao+"\n"
    })

    this.churrascometro.resultado.bebidas.forEach((t:any) => {
      texto = texto +t.calculo +" "+ t.descricao+"\n"
    })

    await Share.share({
      title: 'Minha lista de churrasco',
      text: 'Minha lista de churrasco'+"\n\n"+texto+"\n"+"A Jaú Serve tem sempre os melhores preços, use nosso app e faça suas compras!",
      dialogTitle: 'Minha lista de churrasco',
    });

    /*var currentTime = new Date()

    fintailapi
    .post('/user/churrascometro/salvar', { "customer_id": this.store.state.user.info.id, "lista":{ "nome": "Churrasco "+currentTime.getDate()+"/"+(currentTime.getMonth() + 1) }, "produtos": this.churrascometro.resultado })
    .then((response:any) => {

      let data = response.data;

      this.alerta()

    }, function(response) {

    });*/

  }



  goCalcular() {

    fintailapi
    .post('/user/churrascometro', { "churrascometro": this.churrascometro })
    .then((response:any) => {

      let data = response.data;

      if (data.success) {

        this.churrascometro.resultado = data.churrascometro;

				this.churrascometro.resultado.carnes.forEach((t:any) => {

					  var n = this.churrascometro.resultado
					  , r =  500
					  , o =  400
					  , i =  250
					  , a = "vegetais" === t.tipo ? 0 : (r + o + i) / 3
					  , s = (100 * t.ratio_h / n.total.carnes.h)
					  , l = (s * (r * n.homens) / 100)
					  , c = (s * (a * n.vegetarianos) / 100)
					  , u = (100 * t.ratio_m / n.total.carnes.m)
					  , d = (u * (o * n.mulheres) / 100)
					  , f = (u * (a * n.vegetarianos) / 100)
					  , h = (100 * t.ratio_c / n.total.carnes.c)
					  , p = (h * (i * n.criancas) / 100)
					  , m = (h * (a * n.vegetarianos) / 100)
					  , y = this.kFormatter(((l) + (d) + (p) - ((c) + (f) + (m))).toFixed(10));

					  t.calculo = y;



				});

				this.churrascometro.resultado.acompanhamentos.forEach((t:any) => {

					  var n = this.churrascometro.resultado
					  t.medida = t.medida || "kg";
						var r = n.homens * t.ratio_h
						  , o = n.mulheres * t.ratio_m
						  , i = n.criancas * t.ratio_c
						  , a = "kg" === t.medida.toLocaleLowerCase() ? this.kFormatter(r + o + i) : "l" === t.medida.toLocaleLowerCase() ? this.lFormatter(r + o + i) : Math.ceil(r + o + i) + " " + t.medida;
					  t.calculo = a;

				});

				this.churrascometro.resultado.suprimentos.forEach((t:any) => {

					  var n = this.churrascometro.resultado
					  t.medida = t.medida || "kg";
						var r = n.homens * t.ratio_h
						  , o = n.mulheres * t.ratio_m
						  , i = n.criancas * t.ratio_c
						  , a = "kg" === t.medida.toLocaleLowerCase() ? this.kFormatter(r + o + i) : "l" === t.medida.toLocaleLowerCase() ? this.lFormatter(r + o + i) : Math.ceil(r + o + i) + " " + t.medida;
					  t.calculo = a;

				});
				this.churrascometro.resultado.bebidas.forEach((t:any) => {

					  var n = this.churrascometro.resultado
					  t.medida = t.medida || "kg";
						var r = n.homens * t.ratio_h
						  , o = n.mulheres * t.ratio_m
						  , i = n.criancas * t.ratio_c
						  , a = "kg" === t.medida.toLocaleLowerCase() ? this.kFormatter(r + o + i) : "l" === t.medida.toLocaleLowerCase() ? this.lFormatter(r + o + i) : Math.ceil(r + o + i) + " " + t.medida;
					  t.calculo = a;

				});
				//console.log(this.churrascometro.resultado);
				this.calculoChurrasco = true;

			}

    });


  }

  created() {
    this.setTradicional()
  }

  beforeRouteLeave(to:any, from:any, next:any){
    this.calculoChurrasco = false;
    next();
  }

}

