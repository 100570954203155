


import {
  IonPage,
  IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonModal,
  IonSkeletonText,
  IonCol,
  IonNote,
  IonRow,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonThumbnail,

} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue3-pinch-scroll-zoom"

import 'swiper/swiper-bundle.css'
import SafeArea  from '../components/SafeArea.vue'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonApp,
        IonIcon,
        IonModal,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        IonSkeletonText,
        IonCol,
        IonThumbnail,
        IonNote,
        IonRow,
        Swiper,
        SwiperSlide,
        PinchScrollZoom,
        SafeArea
},
  filters: {
    fallback: function(name : any) {
      console.log(name);
      return name.idtiporecomendacao=="1"
    }
  },
  computed: {
    carts() {
      return this.$store.getters.carts
    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
},
 modules: [Pagination]
 })

export default class Tabloides extends Vue {

  ofertas = {ofertas_da_semana:[], ofertas_destaque:[]}

  tabloides = [
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
  ];


  store = useStore();

  iconRemove = allIcons.trashOutline
  iconSave   = allIcons.saveOutline
  iconOferta = allIcons.pricetag
  iconVoltar = allIcons.chevronBack

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  iconTab = allIcons.newspaperOutline

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  tabloideImagem = "";
  tabloideDescricao = ""

  isOpen = false;

  isOpenRef = false;
  isOpenAtivacao = false;
  modules = [Pagination];

  setOpen = (state: boolean) => this.isOpenRef = state;

  closeTabloide = (state: boolean) => this.isOpen = state;


  showOfertas(t:any, oferta:any){

    let lista_sugestoes = [];

    if(t=='semana'){
      lista_sugestoes = this.ofertas.ofertas_da_semana.map((elem:any) => { return  elem.eans.join(",") });
    }

    if(t=='destaque'){
      lista_sugestoes = this.ofertas.ofertas_destaque.map((elem:any) => { return  elem.eans.join(",") });
    }

    if(oferta.eans == undefined){
      oferta.eans = [];
      this.store.state.user.sugestoes = lista_sugestoes.join(",");
    }else{
      this.store.state.user.sugestoes = oferta.eans.join(",")+","+lista_sugestoes.join(",");
    }

    this.$router.push("/ofertas/"+t)

  }

  showTabloide(image:any,descricao:any) {
    this.isOpen=true;
    this.tabloideImagem = image
    this.tabloideDescricao= descricao
  }

  dashboardOfertas = async () => {

    //this.showLoading()

    fintailapi
    .post('/tabloides', {})
    .then((response:any) => {

      let data = response.data;
      if (data.success) {

        this.tabloides = data.tabloides;
        //this.ofertas = data.ofertas.ofertas;

			}

    });


  }

  voltar() {
    this.$router.back()
  }

  created() {
    this.dashboardOfertas()
  }

  beforeRouteLeave(to:any, from:any, next:any){
    this.isOpenAtivacao = false;
    this.isOpen = false;
    next();
  }

}

