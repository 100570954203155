


import {
  IonPage,
  IonContent ,
  IonItem,
  IonInput,
  IonLabel,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonCol,
  IonRow,
  IonRadio,
  IonModal,alertController,
  IonRadioGroup,IonSpinner,
  IonDatetime
} from '@ionic/vue';

import useVuelidate from '@vuelidate/core'
import { required,  minLength,
  maxLength,
  sameAs, } from '@vuelidate/validators'

import { defineComponent, ref } from 'vue';

import { cart, chevronForward, chevronBack,helpCircle, checkmark, close, eye, eyeOff } from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import { Drivers, Storage } from '@ionic/storage';

import fintailapi from "../api";

import {useStore} from "vuex";

import { uuid } from 'vue-uuid';

import maska from 'maska'

import VueCountdown from '@chenfengyuan/vue-countdown';

import VOtpInput from 'vue3-otp-input';

import {cpf as validarCPF, cnpj as validarCNPJ  } from 'cpf-cnpj-validator';

import moment from 'moment'

import SelectDia  from '../components/SelectDia.vue'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonContent,
    IonPage,
    IonItem,
    IonLabel,
    IonInput,
    IonCol,
    IonRow,
    maska,
    IonRadio,
    IonModal,
    IonRadioGroup,
    useVuelidate,
    VueCountdown,
    alertController,
    IonSpinner,
    VOtpInput,
    IonDatetime,
    SelectDia
  },
  data() {
    return {
      chevronForward,chevronBack,helpCircle, checkmark, close,eye,eyeOff,
      isLast: false,
      isOpenPolitica:false,
      isOpenTermos: false,
      termos:"",
      politica:"",
      isSend:false,
      passwordType:true,
      passwordTypeConf:true,
      yearValues : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      erro_pin:"",
      pin: { enviado: false, data: 0 },
      cadastro:{ uuid: uuid.v1(), sexo : 0, cpf : "", email:"", password:"", password_conf:"", pin_1:"", pin_2:"", pin_3:"", pin_4:"", nascimento:"1990-01-01", mesano_nascimento:"1990-01-01T13:47:20.789",dia_nascimento:"0005-01-01T13:47:20.789" },
      validador:['celular','pin_1','nome','password','privacidade','termosdeuso'],
      validador_tamanho:[10, 1, 5, 8, 1, 1],
      swiper: Swiper,
      store : useStore(),
      storage : new Storage({
        name: '__jauserve',
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }),
    }
  },
  setup() {
    return {
      v$: useVuelidate(),
      modules: [Pagination],
    };
  },
  validations () {
    return {
      cadastro: {
        password: {
          required,
          valid: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsLowercase = true///[a-z]/.test(value)//travei pra passar
            const containsNumber = /[0-9]/.test(value)
            //const containsSpecial = /[#?!@$%^&*-]/.test(value)
            return containsUppercase && containsLowercase && containsNumber /*&& containsSpecial*/ && value.length >= 8
          },
          hasnumber: function(value:any) {
            const containsNumber = /[0-9]/.test(value)
            return  containsNumber
          },
          hasupper: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            return  containsUppercase
          },
          minLength: minLength(8),
          maxLength: maxLength(19),
        },
        password_conf: { required, sameAsPassword: sameAs(this.cadastro.password) },
        cpf: {
          required,
          valid: function(value:any) {

            if(value.length >14){
              return validarCNPJ.isValid(value);
            }else{
              return validarCPF.isValid(value);
            }

          }
        },
        nome: {
          required,
          valid: function(nomeCompleto:any) {
                  // Verificar se o campo não está vazio
                  if (!nomeCompleto) {
                    return false;
                  }

                  // Dividir o nome completo em nome e sobrenome
                  const partesNome = nomeCompleto.split(" ");
                  const nome = partesNome[0];
                  const sobrenome = partesNome.slice(1).join(" ");

                  // Verificar se há pelo menos um nome e um sobrenome
                  if (!nome || !sobrenome) {
                    return false;
                  }

                  // Verificar se o nome e o sobrenome contêm apenas letras
                  const regexApenasLetras = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
                  if (!regexApenasLetras.test(nome) || !regexApenasLetras.test(sobrenome)) {
                    return false;
                  }

                  // Se passou por todas as verificações acima, então o campo é válido
                  return true;
          }
        },
      },
    }
  },

  created() {
    this.storage.create();
  },
   methods: {
    menor(){

      const dataNascimentoMoment = moment(this.cadastro.nascimento, 'YYYY-MM-DD');

      // Criar um objeto Moment para a data atual
      const dataAtual = moment();
      const diferencaAnos = dataAtual.diff(dataNascimentoMoment, 'years');
      return diferencaAnos < 18

    },
    handleOnComplete (value: string){
      this.cadastro.pin_1 = value[0]
      this.cadastro.pin_2 = value[1]
      this.cadastro.pin_3 = value[2]
      this.cadastro.pin_4 = value[3]
      this.toEnd()
    },
    onPin(pin:any) {

      const pin2:any = this.$refs.pin2;
      const pin3:any = this.$refs.pin3;
      const pin4:any = this.$refs.pin4;

      if(pin =='pin2'){
        pin2.focus();
      }else if(pin =='pin3'){
        pin3.focus();
      }else if(pin =='pin4'){
        pin4.focus();
      }

    },  
    changeDt() {

      const dd = moment(this.cadastro.dia_nascimento).format("yy");
      const mmyyy = moment(this.cadastro.mesano_nascimento).format("yyyy-MM");
      this.cadastro.nascimento = mmyyy+"-"+dd

    },
    onSwiper(swiper:any) {
      this.swiper = swiper;
    },
    toStart() {

      if( this.swiper.isBeginning){
        this.$router.back();
      }else
        this.swiper.slidePrev();

    },
    onLast() {
      this.isLast=true;
    },
    onFirst() {
       this.isLast=false;
    },
    async presentAlert(erro:any){
        const alert = await alertController.create({
          header: 'Atenção',
          subHeader: erro.submessage,
          message: erro.message,
          buttons: ['OK'],
        });

        await alert.present();
  },
  async possuiConta(){

        const alert = await alertController.create({
          header: 'CPF já cadastrado!',
          subHeader: "Já existe um cadastro com o CPF informado",
          message: "Você pode fazer o login ou caso não se lembre use o botão esqueci minha senha.",
          buttons: [
            {
              text: 'Ir para login',
              role: 'cancel',
              handler: () => {
                this.goSingUp()
              },
            },
            {
              text: 'Tentar Novamente',
              role: 'confirm',
              handler: () => {

              },
            },
          ],
        });

        await alert.present();

  },
  toEnd() {

      this.cadastro.cpf = this.$route.query.cpf?.toString() || "";

      const validador:string = this.validador[this.swiper.activeIndex]

      const FieldCadastro = (this.cadastro as any)
      if(FieldCadastro[validador] == undefined || FieldCadastro[validador].length < this.validador_tamanho[this.swiper.activeIndex])return

      if(validador == "celular"){

        this. callValidacao()

      }


      if(validador == "cpf"){

        if(!this.v$.cadastro.cpf.$invalid){

          this.checkCPFCNPJ().then((response:any) => {

            const data = response.data
            if(data.success){
              this.swiper.slideNext();
            }else{
              this.possuiConta();
            };


          }).catch((error) => {})

        }
      }else if(validador == "nome"){
     
        if(!this.v$.cadastro.nome.valid.$invalid && FieldCadastro['email'].length > 5 && !this.menor())this.swiper.slideNext();

      }else if(validador == "password"){

        if(!this.v$.cadastro.password.valid.$invalid &&  !this.v$.cadastro.password_conf.sameAsPassword.$invalid)this.swiper.slideNext();

      }else if(validador == "pin_1"){


        this.checkValidacao().then((response:any) => {

          const data = response.data
          if(data.success)this.swiper.slideNext();
          else this.erro_pin="PIN incorreto tente novamente"
   
        }).catch((error) => {});


      }else{

        this.swiper.slideNext();

      }



    },
     showPolitica() {
      this.isOpenPolitica = true;
      this.getPrivacidade();
    },
     showTermos() {
      this.isOpenTermos = true;
      this.getTermos();
    },
    callValidacao() {

      if(this.pin.enviado){

        return;

      }

      fintailapi
      .post('/user/validate', this.cadastro)
      .then((response:any) => {

        const data = response.data
        this.cadastro.uuid = data.uuid

        this.pin.enviado = true;

      })
      .catch((error) => {
      })

    },
    checkValidacao() {

        return fintailapi.post('/user/validate', this.cadastro)

    },
    checkCPFCNPJ() {

      return fintailapi.post('/user/validate/cpf',  this.cadastro )

    },
    callSignUP() {

      if( this.isSend ) return;

      this.isSend = true

      fintailapi
      .post('/user/new', this.cadastro)
      .then((response:any) => {

          const data = response.data

        if(data.success){

            this.storage.set('user', { "email": this.cadastro.email, "password": this.cadastro.password });

            this.store.state.user.id    = data.info.id;
            this.store.state.user.info  = data.info;
            this.store.state.user.token = data.token;
            this.store.state.basket     = data.basket;

            this.store.state.user.isAuthenticated = true;
            this.store.state.user.isGuest = false
            this.store.state.user.isBemvindo = false

            fintailapi.defaults.headers.common['Authorization'] = data.token;

            setTimeout(() => this.$router.push('/') , 400);

            this.isSend=false

        }else{

          if(data.beta){

            this.presentAlert({message:  data.error || data.message, submessage: ""})
            setTimeout(() => this.$router.push('/') , 400);

          }else{

            this.toStart()  
            this.isSend = false
            this.isLast = false;

            this.presentAlert({message:  data.error || data.message, submessage: ""})

          }

        }
        
      }).catch((error) => {

        this.toStart()  
        this.isLast = false
        this.isSend = false

        if (error.response) {

              const data = error.response.data

              this.isLast = false
              this.isSend = false

              if(data){
                this.presentAlert({message: data.message, submessage:""})
              }else{
                this.presentAlert({message: "Por favor, tente novamente!", submessage:"Houve um problema ao enviar seu cadastro"})
              }

              this.isLast =  false

        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }



      })

    },
    goSingUp() {
      this.$router.push("/login")
    },
    goGuest() {

      this.store.state.user.isGuest = true
      this.$router.push("/mercado")

    },
    async getPrivacidade() {

      const c =  await fintailapi.get('/politica/privacidade')

      this.politica= c.data.termo.texto

    },

    async getTermos(){

      const c = await  fintailapi.get('/politica/uso')
      this.termos=  c.data.termo.texto


    },
  },
});

