


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonLoading,
  IonBadge,
  IonSkeletonText,
  IonThumbnail,
  IonCol,
  IonRow,
  IonModal,
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import SafeArea from '../components/SafeArea.vue'
import FooterMenu from '../components/FooterMenu.vue'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        Swiper,
        SwiperSlide,
        IonLoading,
        IonBadge,
        IonSkeletonText,
        IonThumbnail,
        IonCol,
        IonRow,
        IonModal,
        SafeArea,
        FooterMenu

},
  filters: {
    fallback: function(name : any) {
      console.log(name);
      return name.idtiporecomendacao=="1"
    }
  },
  computed: {
    carts() {
      return this.$store.getters.carts
    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
},
 modules: [Pagination]
 })

export default class Promocoes extends Vue {

  oferta = {}

  ofertas = [
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
    { nomeoferta:' ', validade:' ', percentualdesconto:' ', idtiporecomendacao: "1", ordem: 0, value: 'Item 0', imagem: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Vw8AAoEBfymqrywAAAAASUVORK5CYII=" },
  ];


  store = useStore();

  iconRemove = allIcons.trashOutline
  iconSave = allIcons.saveOutline
  iconOferta = allIcons.pricetag
  iconVoltar = allIcons.chevronBack

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline


  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  isOpenRef = false;
  isOpenAtivacao = true;
  isOpenDetalhes = false;

  isOpenLoading = false;
  modules = [Pagination];

  setOpen = (state: boolean) => this.isOpenRef = state;

  getOfertas = async () => {

    fintailapi
    .post('/oferta', {})
    .then((response:any) => {

      let data = response.data;
      if (data.success) {

        this.ofertas = data.ofertas;

        //this.ofertas= this.ofertas.filter(item => item.idtiporecomendacao=="1");

			}

    });

  }

  putOfertas = async (oferta:any) => {

    this.isOpenLoading = true

    fintailapi
    .put('/oferta', { id : oferta.idoferta })
    .then((response:any) => {

      this.isOpenDetalhes = false;
      this.isOpenLoading = false

      let data = response.data;
      if (data.success) {
        oferta.ativado = true;
			}

    });

  }

  showOferta = async (oferta:any) => {
    this.isOpenDetalhes = true;
    this.oferta = oferta
  }

  voltar() {
    this.$router.back()
  }

  created() {
   this.getOfertas()

  }

  beforeRouteLeave(to:any, from:any, next:any){
    this.isOpenAtivacao = false;
    next();
  }

}

