
import {
  IonContent,
  IonHeader,
  IonPage,
  IonList,
  IonMenu,
  IonInput,
  IonRouterOutlet,
  IonTitle,
  IonToolbar,
  menuController,
  IonApp,
  IonIcon,
  IonLabel,
  IonCol,
  IonRow,
  IonMenuToggle,
  IonAlert,
  IonLoading,
  IonToast,
  IonItem,
  IonText,
  IonButton,
  loadingController,
  IonModal,
  toastController
} from '@ionic/vue';

import { cart, chevronBackCircle, chevronBack,close, checkmark, eyeOff,eye,checkmarkDoneCircle   } from 'ionicons/icons';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

import useVuelidate from '@vuelidate/core'
import { required,  minLength,
  maxLength,
  sameAs } from '@vuelidate/validators'

import { Capacitor } from '@capacitor/core';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { Drivers, Storage } from '@ionic/storage';

import { uuid } from 'vue-uuid';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import { StatusBar, Style } from '@capacitor/status-bar';
import maska from 'maska'

import VOtpInput from 'vue3-otp-input';

import {cpf as validarCPF, cnpj as validarCNPJ  } from 'cpf-cnpj-validator';



const isEmailTaken = (value:any) => {
 // return this.cadastro.password;
} // check the email in the server

@Options({components: {IonPage,IonContent, IonCol, IonRow, IonToast, IonLoading, IonText, IonList, IonItem, IonInput, IonLabel, IonToolbar, IonTitle, maska, menuController, IonIcon, IonButton,IonAlert,IonModal,    Swiper, toastController ,
    SwiperSlide, VOtpInput, useVuelidate },
    validations: {
      cadastro: {
        password: { required,
          valid: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            const containsLowercase = true///[a-z]/.test(value)//travei pra passar
            const containsNumber = /[0-9]/.test(value)
            //const containsSpecial = /[#?!@$%^&*-]/.test(value)
            return containsUppercase && containsLowercase && containsNumber /*&& containsSpecial*/ && value.length >= 8
          },
          hasnumber: function(value:any) {
            const containsNumber = /[0-9]/.test(value)
            return  containsNumber
          },
          hasupper: function(value:any) {
            const containsUppercase = /[A-Z]/.test(value)
            return  containsUppercase
          },
          minLength: minLength(8),
          maxLength: maxLength(19),
        },
        cpf: { required,
          valid: function(value:any) {

            if(value.length >14){
              return validarCNPJ.isValid(value);
            }else{
              return validarCPF.isValid(value);
            }

          }
        },
      },
    }



  })
export default class Logout extends Vue {

  storage = new Storage({
    name: '__jauserve',
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
  });

  usernames = "";
  passwords = "";

  submitted = false;
  isOpenRef = false;

  isOpenRefCode = false;
  isOpenRefCodeEmail = false;

  isOpenLoading = false;
  capturaCPF = false;
  capturaCELULAR = false;
  capturaCodUnico = false
  capturaNovaSenha = false

  passwordType = true;
  passwordTypeConf = true;


  swiper=Swiper;



  store = useStore();

  gotoCadastro() {
    this.$router.push('/cadastro');
  }

  goto() {
    this.$router.push('/prelogin');
  }

  voltar() {
    this.$router.back()
  }


  created() {

    this.store.state.user.isFirst = false

    this.storage.create();

    (async () => {

      this.storage.set('user', null);
      this.$router.push('/login');

    })();


  }
  data() {
    return {
      chevronBackCircle,
      chevronBack,
      close,
      checkmark,
      eyeOff,
      eye
    }
  }

}

