


import {
  IonPage, IonContent ,
  IonHeader,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonTitle,
  IonRow,
  IonCol,
  IonModal,
  IonButtons,
  IonSpinner,
  IonBackButton
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'
import SafeArea  from '../components/SafeArea.vue'
import Campanha from '../components/Campanha.vue'
import Raspadinha from '../components/Raspadinha.vue'


Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonHeader,
        IonButton,
        IonButtons,
        IonTitle,
        IonToolbar,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonRow,
        IonCol,
        IonModal,
        IonBackButton,
        IonSpinner,
        Swiper,
        SwiperSlide,
        SafeArea,
        Campanha,
        Raspadinha
},
  filters: {
    fallback: function(name : any) {
      console.log(name);
      return name.idtiporecomendacao=="1"
    }
  },
  computed: {
    carts() {
      return this.$store.getters.carts
    },
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
},
 modules: [Pagination]
 })

export default class Premios extends Vue {

  premios = [];
  
  raspadinha = { selecionada:{}, show: false, iframe: { show:false } }
  campanha   = { selecionada:{}, show: false, iframe: { show:false } }

  campanhas  = [];



  store = useStore();

  iconFechar = allIcons.close
  iconRemove = allIcons.trashOutline
  iconSave = allIcons.saveOutline
  iconOferta = allIcons.pricetag
  iconVoltar = allIcons.chevronBack

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline
  iconPremio = allIcons.giftOutline


  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  isOpenRef = false;
  isOpenAtivacao = false;
  
  modules = [Pagination];

  isLoading= true;
  conta_digital ={ saldo : 0 }

  setOpen = (state: boolean) => this.isOpenRef = state;

  
  ifLoad(ifLoad: any) {
    console.log(ifLoad)
  }
  

  showRaspadinha(raspadinha: any) {

    if( raspadinha.urlparticipacao == "" )return;

    this.raspadinha.selecionada = raspadinha;
    this.raspadinha.show = true;

  }
  
  showCampanha(campanha: any) {

    if( campanha.urlparticipacao == "" )return;

    this.campanha.selecionada = campanha;
    this.campanha.show = true;

  }

  getPremios = async () => {

    fintailapi
    .get('/premios')
    .then((response:any) => {

      this.isLoading= false;
      let data = response.data;

      if (data.success) {

          this.premios = data.premios.premiacao;
          this.campanhas = data.campanhas;

          if(data.cartao.success){
           // this.conta_digital = data.cartao
          }

			}

    });

  }


  voltar() {
    this.$router.back()
  }


  created() {

    this.getPremios()

  }

  beforeRouteLeave(to:any, from:any, next:any){

    this.isOpenAtivacao = false;
    this.isOpenRef = false;

    this.campanha.show = false;
    this.raspadinha.show = false;

    this.campanha.iframe.show = false;
    this.raspadinha.iframe.show = false;

    next();

  }

}

