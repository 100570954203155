

  import {
    IonPage,
    IonContent ,
    IonItem,
    IonMenu,
    IonApp,
    IonList,
    IonLabel,
    IonListHeader,
    IonMenuToggle,
    IonToolbar,
    IonButton,
    IonFooter,
    IonCol,
    IonRow,
    IonIcon,
    IonModal,
    IonButtons,
    IonBackButton
  } from '@ionic/vue';

  import { defineComponent, ref } from 'vue';

  import * as allIcons from 'ionicons/icons';

  import { Options , Vue} from 'vue-class-component';

  import {chevronBackCircle} from 'ionicons/icons';

  import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

  import { Pagination } from "swiper";

  import fintailapi from '../api'

  import 'swiper/swiper-bundle.css'

  import {Vue3Lottie}  from 'vue3-lottie'
  import 'vue3-lottie/dist/style.css'

  import carrinhoAnimation from '../lotties/shopping-cart-loader.json'
  import sacolaAnimation from '../lotties/food-market-app-interaction.json'
  import logoAnimation from '../lotties/logo-animation.json'

  import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue3-pinch-scroll-zoom"


  import SafeArea  from '../components/SafeArea.vue'


@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonList,
        IonMenu,
        IonButton,
        IonToolbar,
        IonApp,
        IonIcon,
        IonLabel,
        IonModal,
        IonCol,
    IonRow,
        IonListHeader,
        IonMenuToggle,
        IonFooter,
        IonBackButton,
        PinchScrollZoom,
        SafeArea
  }
 })

export default class Privacidade extends Vue {

  iconRemove  = allIcons.trashOutline
  iconSave    = allIcons.saveOutline
  iconOferta  = allIcons.pricetag
  iconFechar  = allIcons.close
  iconVoltar  = allIcons.chevronBack

  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  iconFaq = allIcons.help

  iconPolitc = allIcons.trailSign

  iconTermos = allIcons.terminal
  iconTrocas = allIcons.repeat

  isOpen = false;
  isOpenAtivacao = true;

  termos = null

  setOpen = (state: boolean) => this.isOpen = state;

  getPrivacidade = async () => {

      fintailapi
    .get('/politica/privacidade')
    .then((response:any) => {
      this.isOpen=true;
      const data = response.data
      this.termos = data.termo.texto
    })


  }

  getTermos = async () => {

    fintailapi
    .get('/politica/uso')
    .then((response:any) => {
      const data = response.data
      this.termos = data.termo.texto
      this.isOpen=true;
    })


  }

  getTrocas = async () => {

    fintailapi
    .get('/politica/trocas')
    .then((response:any) => {
      const data = response.data
      this.termos = data.termo.texto
      this.isOpen=true;
    })


  }

  getFaq = async () => {

    fintailapi
    .get('/politica/faq')
    .then((response:any) => {
      const data = response.data
      this.termos = data.termo.texto
      this.isOpen=true;
    })


  }

  voltar() {
   this.$router.back()
  }

  created() {
    //this.getTermos()
  }




}





