

import { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader, IonSpinner,IonImg, IonCardTitle,IonButton,IonLabel, IonIcon, IonCol, IonRow, IonFooter, IonModal, IonContent,    IonAccordion,    IonAccordionGroup,    IonItem, } from "@ionic/vue";
import { locationOutline, chevronDown, chevronBack,helpCircle, checkmark, close, add, remove,addCircleOutline  } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: "canais",
  components: { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader,IonSpinner, IonImg, IonIcon, IonCardTitle, IonButton,IonLabel,IonCol,IonRow, IonModal,IonFooter, IonContent, IonAccordion,    IonAccordionGroup,    IonItem,  },
  props: {
    campanha: {
      type: Object,
      required: true,
      default: () => {}
    },
    campanhas: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  data() {
    return {
      isOpenDetalhes : false,
      isLast: false,
      locationOutline,chevronDown, helpCircle, checkmark, close, add, remove,addCircleOutline
    }
  },
  methods: {
    setImg(img:any){
       img = img.replace("https://","//");
       img = img.replace("https:/","//");
       return "https:"+img;
    }
  },
});
