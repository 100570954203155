


import {
  IonPage, IonContent ,
  IonHeader,
  IonInput,
  IonItem,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
IonButton, IonFooter,
  IonIcon,
  IonTitle,
  IonButtons,
  IonTextarea,
  IonCol,
  IonRow,
  IonBadge,
  IonSpinner,
  IonBackButton
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import { Options , Vue} from 'vue-class-component';
import moment from 'moment';
import fintailapi from "../api";

import {useStore} from "vuex";
import { defineComponent } from 'vue';

import SafeArea  from '../components/SafeArea.vue'

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonList,
        IonInput,
        IonMenu,
        IonButton,
        IonCol,
        IonRow,
        IonTitle,
        IonToolbar,
        IonTextarea,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        IonSpinner,
        IonFooter,
        IonBackButton,
        IonBadge,
        SafeArea
}, data() {
    return {
      user_info : useStore().state.user.info,
      geolocation : useStore().state.user.geolocation,
      loadingx:true
    }
  },methods: {
      chatSend() {

        this.chats.push({
          id: 2, tipo: 1, mensagem: this.sendMensagem,
          data: '',
          show: false,
          opcoes: []
        });

        this.toLoad(true)

        fintailapi.patch('/notifications',{ mensagem : this.sendMensagem })
        .then((response:any) => {

          this.toLoad(false)
          let data = response.data;

          if (data.success) {

            this.chats.push(data.message);
            setTimeout(() => {
              this.$refs.myContent.$el.scrollToBottom(300);
            },400)

          }

        });

        setTimeout(() => {
          this.sendMensagem = ""
          this.$refs.myContent.$el.scrollToBottom(300);
        },200)

      },
      chatSendForm(chat:any) {

        chat.is_disable=true;

        this.chats.push({
          id: 2, tipo: 1, mensagem: this.sendMensagem,
          data: '',
          show: false,
          opcoes: []
        });

        fintailapi.post('/formulario',{ mensagem : { idloja: this.geolocation.codigo_loja, loja: this.geolocation.loja, tipo_msg: this.last_choose.mensagem, mensagem: this.sendMensagem, nome: this.user_info.name, email: this.user_info.email, cpf: this.user_info.cpf  } })
        .then((response:any) => {

          this.loading=false
          let data = response.data;
          if (data.success) {

            this.chats.push(data.message);

          }

        });

        setTimeout(() => {
          this.sendMensagem = ""
          this.$refs.myContent.$el.scrollToBottom(300);
        },200)

      }
}})

export default class Suporte extends Vue {

  chats = [
    { id:1, tipo: 0, mensagem: "Olá! Qual das opções você precisa de ajuda?", show: false, opcoes:[
      {mensagem: "Ajuda por WhatsApp" , data:"18-05-2022", link:"https://wa.me/5514998566900?text=Ol%C3%A1+Quero+falar+sobre+um+item+da+JauServe"}
      ],  data:"18-05-2022" },
  ];


  iconVoltar = allIcons.chevronBack
  icon_back = allIcons.chevronBackCircle
  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconClose = allIcons.closeSharp
  iconEnviar = allIcons.send

  loading = true

  chatGpt = false

  sendMensagem = ""

  last_choose = {}

  toLoad(value:any) {
    console.log(value)
    this.loading = value
  }

  toDia(value:any) {
    if(!value)return "";
    return moment(value).format('DD');
  }

  toMese(value:any) {
    if(!value)return "";
    return moment(value).format('MMMM');
  }

  toDiaMes(value:any) {
    if(!value)return "";
    return moment(value).format('DD/MM');
  }


  getNotifications = async () => {

    fintailapi
    .get('/notifications')
    .then((response:any) => {

        this.loading=false
        let data = response.data;
        if (data.success) {

          this.chats = data.chats;

        }

    });

  }


  sendNotifications(id:any){

    return fintailapi.post('/notifications',{ id : id })

  }

  clearNotifications(id:any){

    fintailapi.delete('/notifications/' +encodeURIComponent(id))
    .then((response:any) => {

        this.loading = false
        let data = response.data;
        if (data.success) {

          this.getNotifications()
          //this.chats = data.chats;
        }

    });

  }


  clearAllNotifications(chats:any){

    for (const chat of chats) {
      if(chat.idEntidade)this.clearNotifications(chat.idEntidade)
    }
    this.getNotifications()

  }



  chatOpcao(opcao:any, chat:any) {


    this.last_choose = opcao

    if(chat.tipo != 2){

      chat.show = false;

      if(opcao.link){
          window.open(opcao.link);
      }

      this.chats.push({
        id: 2, tipo: 1, mensagem: opcao.mensagem,
        data: '',
        show: false,
        opcoes: []
      });

      this.loading=true
      this.sendNotifications(opcao.id)
      .then((response:any) => {

        this.loading=false

        let data = response.data;

        if (data.success) {

            this.chats.push(data.message);
            if(data.message.gpt)this.chatGpt=true
        }

      });

    }

  }

  voltar() {
    this.$router.back()
  }


  created() {

    moment.locale("pt-br");

    const inicio = useStore().state.user.suporte

    if( inicio ){

      this.loading=false

      this.chats.push({
        id: 2, tipo: 1, mensagem: inicio.mensagem,
        data: '',
        show: false,
        opcoes: []
      });

      if(inicio.tipo == 1){
        this.chats.push({
          id: 2, tipo: 0, mensagem: "Sua solicitação foi recebida e encaminhada para o setor responsável, foi gerado um número de protocolo:"+moment().format('DDMMYYYYHm'),
          data: '',
          show: false,
          opcoes: []
        });
      }

      useStore().state.user.suporte=null;

    }else{
      this.getNotifications()
    }



  }

}

