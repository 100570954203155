


import {
  IonPage,
  IonContent,
  IonItem,
  IonMenu,
  IonAlert,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
  IonFooter,
  IonIcon,
  IonTitle,
  IonBackButton,
  IonCol,
  alertController,
  IonRow,
  IonModal,
  IonSpinner,
} from '@ionic/vue';

import * as allIcons from 'ionicons/icons';

import  { Options , Vue} from 'vue-class-component';
import fintailapi from "../api";
import {useStore} from "vuex";
import { Capacitor } from '@capacitor/core';


import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';


//import  BcodeP from '@capacitor-community/barcode-scanner';

import {
  Barcode,
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
} from '@capacitor-mlkit/barcode-scanning';




import SafeArea  from '../components/SafeArea.vue'


import Produto from '../components/Produto.vue'

Vue.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Options({components: {
        IonContent,
        IonPage,
        IonItem,
        IonAlert,
        IonList,
        IonMenu,
        IonButton,
        IonTitle,
        IonToolbar,
        IonApp,
        IonIcon,
        IonLabel,
        IonListHeader,
        IonMenuToggle,
        alertController,
        IonFooter,
        IonBackButton,
        IonCol,
        IonRow,
        IonModal,
        SafeArea,
        Produto,
        IonSpinner
}
})




export default class Gondola extends Vue {


  store = useStore();

  router = this.$router

  onSave=false

  iconRemove = allIcons.trashOutline
  iconSave   = allIcons.saveOutline
  iconOferta = allIcons.pricetag
  iconVoltar = allIcons.chevronBack

  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox

  iconPlus  = allIcons.addCircleOutline
  iconMinus = allIcons.removeCircleOutline

  icon_qr   =  allIcons.qrCode

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove

  isOpenRef      = false;
  isOpenAtivacao = false;
  isOpenDetalhes = false;

  scannerActive  = false

  produtos: any[] = [];

  setOpen = (state: boolean) => this.isOpenRef = state;



  salvarLista () {

    this.onSave=true

    const alert = alertController.create({
      header: 'Lista de Compras',
      subHeader: '',
      message: 'Crie uma lista para facilitar suas futuras compras.',
      buttons: [
        {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              this.onSave = false;
            }
        },
        {
            text: 'Salvar',
            handler: (alertData) => { //takes the data
                fintailapi
                .post('/produtos/lista/salvar', { "customer_id": this.store.state.user.id, "produtos":this.produtos.map(produto => {return produto.id_jau; }), "lista" : { "nome" : alertData.nome_lista} })
                .then((response:any) => {
                    const alert = alertController.create({
                      header: 'Pronto!',
                      subHeader: 'Você criou uma nova lista de compras!',
                      message: 'Para consultá-la, acesse o ícone LISTA DE COMPRAS no menu principal',
                      buttons: ['OK'],
                    }).then((response:any) => {
                      this.onSave=false
                      response.present();
                    })
                })
            }
        }
    ],
      inputs: [
        {
          placeholder: 'Nome da Lista',
          name: 'nome_lista',
          type: 'text'
        }
      ]
    }).then((response:any) => {

      response.present();

      });

    //await alert.present();
  }

  getProduto(ean:any, router:any) {

      fintailapi
      .get('/produto/scan/'+ean)
      .then((response:any) => {
        const data = response.data

        const result = data.produto;
        if (data.success) {

          this.produtos.push(data.produto)

        }else if (data.estoque) {

          alertController.create({
            header: 'Sem Estoque',
            message: "Não temos em estoque este produto no momento!",
            buttons: [
              {
                text: 'Ok',
                role: 'confirm',
                handler: async (c) => {
                  await this.askUser(router)
                },
              },
              {
                text: 'Ver Sugestões',
                role: 'cancel',
                handler: async () => {
                  this.store.state.user.sugestoes = data.sugestoes.join(",");
                  router.push('/sugestoes');
                },
              },
            ]
          }).then((response:any) => {

            response.present();

          });

        }else{

          alertController.create({
            header: 'Produto',
            message: "Produtos não encontrado em nossa loja online!",
            buttons: [
              {
                text: 'Tentar Novamente',
                role: 'confirm',
                handler: async () => {
                  this.askUser(router)
                },
              },
              {
                text: 'Sair',
                role: 'cancel',
                handler: () => {
                  this.voltar(router)
                },
              },
            ]
          }).then((response:any) => {

            response.present();

          });

        }

      })

  }



  startScan = async (router:any) => {

    if(Capacitor.getPlatform() == "web"){
      await this.getProduto("7798051301391",  router)
      return
    }


    await BarcodeScanner.isSupported()

    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.Ean13,BarcodeFormat.Ean8], 
    });
    barcodes[0].rawValue;

    if (barcodes[0].rawValue) {

      this.getProduto(barcodes[0].rawValue,  router)

    }

  };

  preparar = async () =>{
    //return BarcodeScanner.prepare();

   

  };


  stopScan = async () => {


    if(Capacitor.getPlatform() == "web"){
      return
    }

    try {

      //await BarcodeScanner.showBackground();
      await BarcodeScanner.stopScan();

    }catch (e) {
      console.log(e);
    }

  };

  askUser  = async (router:any) => {

    if(Capacitor.getPlatform() == "web"){
      await this.startScan(router);
      return
    }

    try {



        let cx = await this.preparar();

        /*const status = await BcodeP.BarcodeScanner.checkPermission();

        if ( status.denied ) {
          BcodeP.BarcodeScanner.openAppSettings();
        }
*/
        //if( status.neverAsked ) {
            Camera.requestPermissions()
       // }

        const c = true;

        if (c) {

          if(Capacitor.getPlatform() == "android"){

            await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable().then(async (data:any) => {
                if (data.available) {
                    // Start the barcode scanner
                    this.scannerActive = true
                    await this.startScan(router);

                } else {
                    // Install the Google ML Kit barcode scanner
                    await BarcodeScanner.installGoogleBarcodeScannerModule().then(async () => {
                      
                      this.scannerActive = true
                      await this.startScan(router);

                    });
                }
            });
            
          }else{
            
            this.scannerActive = true
            await this.startScan(router);

          }

        } else {

          await this.stopScan();

        }

    }catch (e) {
      this.scannerActive = false
    }
  };

  checkPermission = async () => {
/*
    const status = await BcodeP.BarcodeScanner.checkPermission();

    if ( status.denied || status.neverAsked ) {
      // the user denied permission for good
      // redirect user to app settings if they want to grant it anyway
      BcodeP.BarcodeScanner.openAppSettings();
        return false
    }else{
      return true
    }
*/

  };

  goto = async (u:any) => {
    this.voltar(this.$router)
  }

  voltar(router:any) {

    if(router)router.push('/')
    else  this.$router.push('/')

  }

  created() {

    if(Capacitor.getPlatform() == "web"){
      this.getProduto("7896110005874",  this.$router)
    }else{
      this.askUser(this.$router)
    }

  }

  beforeDestroy = async () => {
    await this.stopScan()
  }

  deactivated = async () => {
    await this.stopScan()
  }

  updated = async () => {
    await this.stopScan()
  }

  beforeRouteLeave(to:any, from:any, next:any){

    this.isOpenRef      = false;
    this.isOpenAtivacao = false;
    this.isOpenDetalhes = false;

    this.scannerActive  = false

    this.stopScan() .then((response:any) => {
      next();
    });

  }

}
