
import {
  IonCheckbox,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonInfiniteScroll,
    IonInfiniteScrollContent,
  IonHeader,
  IonLabel,
  IonRadio,
  IonToggle,
   IonFab,
  IonFabButton,
  IonFabList,
  IonModal,
  IonPage,
  IonRow,
  IonList,
  IonBadge,
  IonToolbar,
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon, IonCol,
  IonTitle,
  IonButtons,
  menuController
  , loadingController,
} from '@ionic/vue'

import * as allIcons from 'ionicons/icons'

import { Options, Vue } from 'vue-class-component'

import fintailapi from '../api'

import { useStore } from 'vuex'

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import 'swiper/swiper-bundle.css'

import { Drivers, Storage } from '@ionic/storage'

import { Geolocation } from '@capacitor/geolocation'

import Produto from '../components/Produto.vue'
import SafeArea  from '../components/SafeArea.vue'

import FooterMenu from '../components/FooterMenu.vue'

@Options({
  components: { Produto, IonModal, Swiper, SwiperSlide, IonLabel, IonInput,  IonFab,  IonFabButton,    IonInfiniteScroll,
    IonInfiniteScrollContent,  IonFabList,IonList, IonItem, IonPage, IonHeader, IonContent, IonGrid, IonFooter, IonIcon, IonCol, IonBadge, IonRow, IonToolbar, IonTitle, IonButtons, IonButton, SafeArea, FooterMenu },
  computed: {
    cartItemNumber() {
      return this.$store.getters.cartItemNumber
    }
  }})
export default class Pesquisar extends Vue {

  autoCompleteProdutos = []
  produtosProcurar = ""

  isDisabled = true


  ultima_pesquisa_auto = 0


  produtos = []

  navigate (url : string) {
    this.$router.push(url)
  }

  voltar() {
    this.$router.back()
  }

  autoComplete () {

    clearTimeout(this.ultima_pesquisa_auto);

    this.ultima_pesquisa_auto = setTimeout(()=>{

      fintailapi
      .get('/produtos/sugestoes/'+this.produtosProcurar)
      .then((response:any) => {
        const data = response.data
        this.autoCompleteProdutos = data
      })

    }, 1000)


  }

  showProdutos(pesquisa:any){

    clearTimeout(this.ultima_pesquisa_auto);
    this.autoCompleteProdutos = []
    this.getProdutos(pesquisa);

  }

  getProdutos(pesquisa:any) {

    fintailapi
    .post('/produtos/sugestoes/procurar/'+pesquisa, {})
    .then((response:any) => {

      const data = response.data
      this.produtos = data

      this.produtos.map((produto: any) => {

        let p = this.store.getters.productIncart(produto.id);

        if(p){

          produto.incart = true;
          produto.qtd    = p.quantity;

        }

      });



    })

  }


  getProdutosSugestoes(pesquisa:any) {

    fintailapi
    .post('/produtos/sugestoes/lista/ids', {"ids" : pesquisa})
    .then((response:any) => {

      const data = response.data
      this.produtos = data

      this.produtos.map((produto: any) => {

        let p = this.store.getters.productIncart(produto.id);

        if(p){

          produto.incart = true;
          produto.qtd    = p.quantity;

        }

      });



    })

  }


  showLoading = async () => {

    const loading = await loadingController.create({
      message: '',
      duration: 4000
    })
    loading.present()

  }


  //ICONES
  iconRight  = allIcons.chevronForward
  iconDow    = allIcons.chevronDown
  iconVoltar = allIcons.chevronBack

  iconPlus   = allIcons.addCircleOutline
  iconMinus  = allIcons.removeCircleOutline

  iconPlusL  = allIcons.add
  iconMinusL = allIcons.remove
  iconLayer  = allIcons.layers

  iconInicio    = allIcons.homeOutline
  iconSecoes    = allIcons.gridOutline
  iconPromocoes = allIcons.bagHandleOutline
  iconLojas     = allIcons.storefrontOutline

  icon_user = allIcons.personCircle
  icon_chat = allIcons.chatbox
  icon_churras = allIcons.restaurant
  icon_recarga = allIcons.phonePortrait
  icon_pesquisa= allIcons.search
  icon_qr   = allIcons.qrCode
  icon_home = allIcons.home
  icon_cart = allIcons.cart
  icon_promo = allIcons.pricetag
  icon_map = allIcons.map
  icon_location = allIcons.locationOutline
  iconLocationF = allIcons.location


  store = useStore();


  mounted () {
    //this.$nextTick(function () {
      this.getProdutosSugestoes(this.store.state.user.sugestoes);
   // })
  }



}

