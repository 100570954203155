


import {
  IonPage,
  IonContent ,
  IonItem,
  IonMenu,
  IonApp,
  IonList,
  IonLabel,
  IonListHeader,
  IonMenuToggle,
  IonToolbar,
  IonButton,
   IonFooter,
  IonIcon,
  IonRow,
  IonCol,
  IonButtons,
  IonBackButton
} from '@ionic/vue';

import { defineComponent, ref } from 'vue';

import * as allIcons from 'ionicons/icons';

import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import { Pagination } from "swiper";

import 'swiper/swiper-bundle.css'

import {Vue3Lottie}  from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'


export default defineComponent({
  components: {
    Vue3Lottie,
    Swiper,
    SwiperSlide,
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonContent,
    IonPage,
    IonRow,
  IonCol,
  },
  data() {
    return {
      isLast: false,
      swiper: Swiper
    }
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
   methods: {
    onSwiper(swiper:any) {
      this.swiper = swiper;
    },
    toEnd() {
      this.swiper.slideNext();
    },
    goLogin() {
      this.$router.push("/login")
    },
  },
});

