

import { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader, IonSpinner,IonImg, IonCardTitle,IonButton,IonLabel, IonIcon, IonCol, IonRow, IonFooter, IonModal, IonContent,    IonAccordion,    IonAccordionGroup,    IonItem,         IonPage,
        IonAlert,
        IonList,
        IonMenu,
        IonTitle,
        IonToolbar,
        IonBadge,
        IonApp,
        IonListHeader,
        IonHeader,
        IonMenuToggle,
        IonBackButton,
        IonButtons,
        IonNote,

        IonTextarea,} from "@ionic/vue";
import { locationOutline, chevronDown, chevronBack,helpCircle, checkmark, close, add, remove,addCircleOutline, pricetag, star, map, card,reader,calendar   } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import {useStore} from "vuex";
import moment from 'moment';
import fintailapi from "../api";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'

import 'swiper/swiper-bundle.css'


/*
  iconDetalhes =  allIcons.pricetag
  iconAvaliacao =  allIcons.star
  iconEndereco =  allIcons.map
  iconPay =  allIcons.card
  iconResumo =  allIcons.reader

  star = allIcons.star
*/
export default defineComponent({
  name: "canais",
  components: { IonThumbnail, Swiper, SwiperSlide, IonSkeletonText,  IonCard, IonCardHeader,IonSpinner, IonImg, IonIcon, IonCardTitle, IonButton,IonLabel,IonCol,IonRow, IonModal,IonFooter, IonContent, IonAccordion,    IonAccordionGroup,    IonItem,
        IonPage,
        IonAlert,
        IonList,
        IonMenu,
        IonTitle,
        IonToolbar,
        IonBadge,
        IonApp,
        IonListHeader,
        IonHeader,
        IonMenuToggle,
        IonBackButton,
        IonButtons,
        IonNote,
        IonTextarea,  },
  props: {
    pedido: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isOpenDetalhes : false,
      isLast: false,
      total_itens: 0,
      store : useStore().state,
      baseURL: fintailapi.defaults.baseURL ,
      locationOutline,chevronDown, helpCircle, checkmark, close, add, remove,addCircleOutline, pricetag, star, map, card, reader,calendar
    }
  },
  emits: ["onAvaliar", "onBack" ],
  methods: {
    comprarNovamente() {
      const lista_sugestoes =  this.pedido.product_items.map((elem:any) => { return  elem.product_id });
      this.store.user.sugestoes = lista_sugestoes.join(",");
      this.$router.push('/sugestoes');
    },
    totalItem: function(){

      let sum = 0;
      for(let i = 0; i < this.pedido.product_items.length; i++){
        sum += parseFloat(this.pedido.product_items[i].quantity);
      }

     return sum;
   },
    getAvaliacao(current_rating:any) {

      if(!current_rating){
        current_rating = {}
      }

      return current_rating;
    },

    toHora(value:any) {
      if(!value)return "";
      moment.locale('pt-br');
      return moment(value).format('HH')+"h";
    },

    toDia(value:any) {
          if(!value)return "";
          moment.locale('pt-br');
      return moment(value).format('DD');
    },

    toMese(value:any) {
          if(!value)return "";
          moment.locale('pt-br');
      return moment(value).format('MMMM');
    },

    toDiaMes(value:any) {
      if(!value)return "";
      moment.locale('pt-br');
      return moment(value).format('DD/MM');
    },

    avaliar() {
      this.$emit("onAvaliar", "")
    },

  },
});
